import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Award, Users, Clock, MapPin, CheckCircle, Star } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function AboutUs() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Über Uns | Höfler Schädlingsbekämpfung"
        description="Lernen Sie unser Team von zertifizierten Schädlingsbekämpfern kennen. ✓ IHK-geprüft ✓ Über 15 Jahre Erfahrung ✓ Modernste Methoden"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1521791136064-7986c2920216?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Über Uns
            </h1>
            <p className="text-xl text-gray-300">
              Ihr zuverlässiger Partner für professionelle Schädlingsbekämpfung seit über 15 Jahren
            </p>
          </div>
        </div>
      </motion.section>

      {/* Main Content */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Company Values */}
          <motion.div 
            className="grid md:grid-cols-3 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            {[
              {
                icon: Shield,
                title: 'Expertise',
                description: 'IHK-geprüfte Schädlingsbekämpfer mit kontinuierlicher Weiterbildung'
              },
              {
                icon: Users,
                title: 'Kundenorientierung',
                description: 'Individuelle Lösungen und persönliche Beratung für jeden Kunden'
              },
              {
                icon: Award,
                title: 'Qualität',
                description: 'Modernste Methoden und höchste Qualitätsstandards'
              }
            ].map((value, idx) => (
              <div key={idx} className="bg-white rounded-xl shadow-lg p-8">
                <value.icon className="h-12 w-12 text-[#e16b28] mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </motion.div>

          {/* Company Story */}
          <motion.div 
            className="bg-white rounded-2xl shadow-xl p-8 mb-16"
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Unsere Geschichte</h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-4">
                Seit der Gründung im Jahr 2008 hat sich Höfler Schädlingsbekämpfung zu einem 
                führenden Anbieter für professionelle Schädlingsbekämpfung in Bayern und 
                Oberösterreich entwickelt.
              </p>
              <p className="mb-4">
                Was als Ein Mann Unternehmen begann, ist heute ein modernes
                Dienstleistungsunternehmen mit Spezialiserung und ausgebildeten Fachleuten. Unsere Kombination 
                aus traditionellem Handwerk und innovativer Technologie macht uns zum 
                verlässlichen Partner für Privat- und Geschäftskunden.
              </p>
              <p>
                Die kontinuierliche Weiterbildung unserer Mitarbeiter und der Einsatz 
                modernster Methoden garantieren unseren Kunden stets die bestmögliche Lösung 
                für ihre individuellen Anforderungen.
              </p>
            </div>
          </motion.div>

          {/* Certifications & Awards */}
          <motion.div 
            className="grid md:grid-cols-2 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">Zertifizierungen</h3>
              <div className="space-y-4">
                {[
                  'IHK-geprüfter Schädlingsbekämpfer',
                  'Sachkundenachweis nach §4 Abs.1a',
                  'Fallenjagd in befriedeten Bezirken',
                  'Fachberater Wasserschadensanierung'
                ].map((cert, idx) => (
                  <div key={idx} className="flex items-center">
                    <CheckCircle className="h-5 w-5 text-[#e16b28] mr-3 flex-shrink-0" />
                    <span className="text-gray-700">{cert}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-8">
              <h3 className="text-2xl font-bold text-gray-900 mb-6">Zahlen & Fakten</h3>
              <div className="space-y-4">
                {[
                  '15+ Jahre Erfahrung',
                  '2 Ländern tätig',
                  '3000+ betreute Kunden',
                  'spezialisierte Fachbereiche'
                ].map((award, idx) => (
                  <div key={idx} className="flex items-center">
                    <Star className="h-5 w-5 text-[#e16b28] mr-3 flex-shrink-0" />
                    <span className="text-gray-700">{award}</span>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>

          {/* Service Areas */}
          <motion.div 
            className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8 text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-2xl font-bold mb-6">Unsere Standorte</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  region: 'Oberbayern',
                  office: 'München',
                  phone: '+49(0)89-21544167'
                },
                {
                  region: 'Niederbayern',
                  office: 'Passau',
                  phone: '+49(0)8542-9699769'
                },
                {
                  region: 'Oberösterreich',
                  office: 'Schärding',
                  phone: '+43(0)720-317724'
                }
              ].map((location, idx) => (
                <div key={idx} className="space-y-2">
                  <h3 className="font-semibold text-lg flex items-center">
                    <MapPin className="h-5 w-5 text-[#e16b28] mr-2" />
                    {location.region}
                  </h3>
                  <div className="text-gray-300">
                    <p>Büro {location.office}</p>
                    <a 
                      href={`tel:${location.phone}`}
                      className="text-[#e16b28] hover:text-[#c85d22]"
                    >
                      {location.phone}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}
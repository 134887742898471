import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Clock, Leaf, MapPin, AlertTriangle, CheckCircle, Bug, Phone, Star, Building2, Home } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function Rattenbekaempfung() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Rattenbekämpfung Bayern & Oberösterreich | Festpreisgarantie ✓"
        description="Professionelle Rattenbekämpfung & Mäusebekämpfung ✓ Festpreisgarantie ✓ IHK-zertifiziert ✓ Nachhaltige Lösungen ➤ Jetzt kostenlos beraten lassen!"
        keywords="Rattenbekämpfung, Mäusebekämpfung, Nagetierbekämpfung, Bayern, Oberösterreich, Kammerjäger"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1589874186480-ecd085096d45?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Professionelle Rattenbekämpfung & Mäusebekämpfung
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Effektive und nachhaltige Nagetierbekämpfung durch IHK-zertifizierte Experten
            </p>
            <div className="flex flex-wrap gap-4">
              {[
                { icon: Star, text: 'Festpreisgarantie' },
                { icon: Shield, text: 'IHK-zertifiziert' },
                { icon: Leaf, text: 'Nachhaltige Methoden' }
              ].map((item, idx) => (
                <div key={idx} className="flex items-center bg-white/10 px-4 py-2 rounded-full">
                  <item.icon className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-white">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.section>

      {/* Gefahren-Box */}
      <div className="relative -mt-10 z-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="bg-white rounded-xl shadow-xl p-6 border-l-4 border-[#e16b28]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-xl font-bold text-gray-900 mb-4">Gefahren durch Ratten & Mäuse:</h2>
            <div className="grid md:grid-cols-3 gap-4">
              {[
                'Übertragung von Krankheitserregern',
                'Massive Gebäudeschäden',
                'Verunreinigung von Lebensmitteln',
                'Gefährdung der Betriebshygiene',
                'Schäden an Elektroinstallationen',
                'Imageschaden für Unternehmen'
              ].map((danger, idx) => (
                <div key={idx} className="flex items-center bg-gray-50 p-3 rounded-lg">
                  <AlertTriangle className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-gray-700">{danger}</span>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Bekämpfungsprozess */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="text-center mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Professionelle Nagetierbekämpfung in 3 Schritten
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                step: '1',
                title: 'Befallsanalyse',
                items: [
                  'Ermittlung des Befallsumfangs',
                  'Identifikation der Nagetierart',
                  'Lokalisierung von Eintrittspforten',
                  'Dokumentation von Befallsspuren'
                ]
              },
              {
                step: '2',
                title: 'Bekämpfungsstrategie',
                items: [
                  'Moderne Köderboxen',
                  'Mechanische Fallen',
                  'Elektronische Überwachung',
                  'Installation von Barrieren'
                ]
              },
              {
                step: '3',
                title: 'Prävention',
                items: [
                  'Abdichtung von Eintrittspforten',
                  'Bauliche Beratung',
                  'Hygieneschulungen',
                  'Regelmäßige Kontrollen'
                ]
              }
            ].map((phase, idx) => (
              <motion.div
                key={idx}
                className="relative bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: idx * 0.2 }}
              >
                <div className="absolute -top-4 -left-4 w-12 h-12 bg-[#e16b28] rounded-full flex items-center justify-center text-white text-xl font-bold">
                  {phase.step}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4 mt-4">{phase.title}</h3>
                <ul className="space-y-3">
                  {phase.items.map((item, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Einsatzbereiche */}
      <section className="py-20 bg-white" ref={ref}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="text-center mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Einsatzbereiche
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Gewerbliche Objekte */}
            <motion.div
              className="bg-gray-50 rounded-xl p-6"
              initial={{ opacity: 0, x: -20 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
            >
              <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                <Building2 className="h-6 w-6 text-[#e16b28] mr-2" />
                Gewerbliche Objekte
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {[
                  'Lebensmittelbetriebe',
                  'Restaurants & Hotels',
                  'Lagerhallen & Logistik',
                  'Produktionsstätten',
                  'Einzelhandel',
                  'Bürogebäude'
                ].map((area, idx) => (
                  <div key={idx} className="flex items-center bg-white p-3 rounded-lg">
                    <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                    <span className="text-gray-700">{area}</span>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* Private Bereiche */}
            <motion.div
              className="bg-gray-50 rounded-xl p-6"
              initial={{ opacity: 0, x: 20 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
            >
              <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                <Home className="h-6 w-6 text-[#e16b28] mr-2" />
                Private Bereiche
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {[
                  'Einfamilienhäuser',
                  'Mehrfamilienhäuser',
                  'Garten & Außenanlagen',
                  'Dachböden & Keller',
                  'Garagen & Schuppen',
                  'Grundstücke'
                ].map((area, idx) => (
                  <div key={idx} className="flex items-center bg-white p-3 rounded-lg">
                    <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                    <span className="text-gray-700">{area}</span>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Vorteile */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8 text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-2xl font-bold mb-6">Ihre Vorteile mit uns</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[
                {
                  icon: Star,
                  title: 'Festpreisgarantie',
                  points: ['Transparente Preise', 'Keine versteckten Kosten', 'Faire Kalkulation']
                },
                {
                  icon: Shield,
                  title: 'Zertifizierte Expertise',
                  points: ['IHK-geprüft', 'Modernste Methoden', 'Regelmäßige Schulungen']
                },
                {
                  icon: Leaf,
                  title: 'Umweltbewusst',
                  points: ['Nachhaltige Lösungen', 'Tierschutzgerecht', 'Umweltschonend']
                },
                {
                  icon: Bug,
                  title: 'Effektive Bekämpfung',
                  points: ['Professionelle Analyse', 'Gezielte Maßnahmen', 'Nachhaltige Wirkung']
                }
              ].map((advantage, idx) => (
                <div key={idx} className="space-y-3">
                  <div className="flex items-center">
                    <advantage.icon className="h-6 w-6 text-[#e16b28] mr-2" />
                    <h3 className="font-semibold text-lg">{advantage.title}</h3>
                  </div>
                  <ul className="space-y-2">
                    {advantage.points.map((point, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <CheckCircle className="h-4 w-4 text-[#e16b28] mr-2" />
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
}
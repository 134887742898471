import React from 'react';
import { motion } from 'framer-motion';

interface TabNavigationProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

export default function TabNavigation({ activeTab, onTabChange }: TabNavigationProps) {
  return (
    <div className="flex justify-center space-x-8 mb-12">
      <button
        onClick={() => onTabChange('blog')}
        className={`pb-2 px-4 font-medium transition-colors duration-200 relative ${
          activeTab === 'blog'
            ? 'text-[#0C2952]'
            : 'text-gray-500 hover:text-[#0C2952]'
        }`}
      >
        Blog
        {activeTab === 'blog' && (
          <motion.div 
            layoutId="activeTab"
            className="absolute bottom-0 left-0 w-full h-0.5 bg-[#0C2952]" 
          />
        )}
      </button>
      <button
        onClick={() => onTabChange('schaedlingslexikon')}
        className={`pb-2 px-4 font-medium transition-colors duration-200 relative ${
          activeTab === 'schaedlingslexikon'
            ? 'text-[#0C2952]'
            : 'text-gray-500 hover:text-[#0C2952]'
        }`}
      >
        Schädlingslexikon
        {activeTab === 'schaedlingslexikon' && (
          <motion.div 
            layoutId="activeTab"
            className="absolute bottom-0 left-0 w-full h-0.5 bg-[#0C2952]" 
          />
        )}
      </button>
    </div>
  );
}
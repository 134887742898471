import { useState, useEffect } from 'react';
import { BlogPost, RelatedPost } from '../types/blog';
import { fetchPost } from '../utils/blog/api';

export function useBlogPost(slug: string | undefined) {
  const [post, setPost] = useState<BlogPost | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<RelatedPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPost = async () => {
      if (!slug) {
        setError('Kein Beitrag gefunden');
        setLoading(false);
        return;
      }

      try {
        const { post, relatedPosts } = await fetchPost(slug);
        setPost(post);
        setRelatedPosts(relatedPosts);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Ein Fehler ist aufgetreten');
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [slug]);

  return { post, relatedPosts, loading, error };
}
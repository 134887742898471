import { serviceAreas } from '../routes/locations';

// Service-specific keywords
export const serviceKeywords = [
  'Schädlingsbekämpfung',
  'Kammerjäger',
  'Rattenbekämpfung',
  'Hausbock Dachstuhl',
  'Holzschutz',
  'Taubenabwehr',
  'Schädlingsmonitoring',
  'Mäusebekämpfung',
  'Bettwanzenbekämpfung mit Hitze',
  'Bettwanzenbekämpfung thermisch',
  'Bettwanzenbekämpfung mit Garantie',
  'HACCP Monitoring',
  'Wespenbekämpfung',
  'Taubenkotreinigung',
  'Leichenfundortreinigung'
];

// Generate location-specific keywords
const generateLocationKeywords = () => {
  const keywords: string[] = [];
  
  Object.entries(serviceAreas).forEach(([country, regions]) => {
    Object.entries(regions).forEach(([region, cities]) => {
      cities.forEach(city => {
        keywords.push(
          `Schädlingsbekämpfung ${city.city}`,
          `Kammerjäger ${city.city}`,
          `Schädlingsbekämpfer ${city.city}`,
          `Schädlingsbekämpfung ${region}`,
          ...city.districts.map(district => `Schädlingsbekämpfung ${district}`)
        );
      });
    });
  });
  
  return [...new Set(keywords)]; // Remove duplicates
};

export const locationKeywords = generateLocationKeywords();

// Combined keywords for overall SEO strategy
export const allKeywords = [...new Set([...serviceKeywords, ...locationKeywords])];

// Keyword combinations for specific services and locations
export const generateServiceLocationKeywords = (service: string, location: string) => {
  return [
    `${service} ${location}`,
    `${service} in ${location}`,
    `${service.replace('bekämpfung', 'bekämpfer')} ${location}`
  ];
};

// Meta description generator
export const generateMetaDescription = (city: string, region: string): string => {
  return `【Schädlingsbekämpfung ${city}】Professionelle Beratung ✓ Festpreisgarantie ✓ IHK-zertifizierte Experten ➤ Kostenlose Beratung anfordern!`;
};
import React from 'react';
import SEO from '../components/SEO';

export default function Impressum() {
  return (
    <>
      <SEO 
        title="Impressum | Höfler Schädlingsbekämpfung"
        description="Impressum und rechtliche Informationen der Höfler Schädlingsbekämpfung. Ihr zertifizierter Partner für Schädlingsbekämpfung in Bayern & Oberösterreich."
      />
      
      <div className="py-20 bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-2xl shadow-lg p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Impressum</h1>
            
            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Höfler Schädlingsbekämpfung</h2>
              <p className="text-gray-600">
                Inhaber: Andreas Höfler<br />
                Richard-Billinger-Weg 2<br />
                4774 Sankt Marienkirchen b. Schärding
              </p>

              <div className="mt-6">
                <p className="text-gray-600">
                  Tel: <a href="tel:+43720317724" className="text-[#e16b28] hover:text-[#c85d22]">+43(0)720-317724</a><br />
                  Mail: <a href="mailto:office@hoefler-sbk.eu" className="text-[#e16b28] hover:text-[#c85d22]">office@hoefler-sbk.eu</a><br />
                  Web: <a href="https://www.hoefler-sbk.eu" className="text-[#e16b28] hover:text-[#c85d22]">www.hoefler-sbk.eu</a>
                </p>
              </div>

              <div className="mt-6">
                <p className="text-gray-600">
                  UID: ATU80920229
                </p>
              </div>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Aufsichtsbehörde</h2>
              <p className="text-gray-600">
                Bezirkshauptmannschaft Schärding
              </p>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Betriebshaftpflichtversicherung</h2>
              <p className="text-gray-600">
                WIENER STÄDTISCHE Versicherung AG<br />
                Schottenring 30<br />
                A-1011 Wien
              </p>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Bildrechte</h2>
              <ul className="list-disc pl-5 text-gray-600 space-y-1">
                <li>© stockpics – Fotolia.com</li>
                <li>© typomaniac – Fotolia.com</li>
                <li>© wladimir1804 – Fotolia.com</li>
                <li>© rahoong – Fotolia.com</li>
                <li>© C. Schüßler – Fotolia.com</li>
                <li>© zuki70 – Fotolia.com</li>
                <li>© Mike Lorenz – Fotolia.com</li>
                <li>© aorphoto – Fotolia.com</li>
                <li>© Africa Studio – Fotolia.com</li>
                <li>© Kletr – Fotolia.com</li>
                <li>© ChiccoDodiFC – Fotolia.com</li>
                <li>© Pixabay</li>
                <li>© Pexels</li>
              </ul>

              {/* ECG Banner */}
              <div className="flex justify-center mt-12">
                <a 
                  href="https://www.wkoecg.at/Ecg.aspx?FirmaID=62130076-b9d1-400f-a92a-2a1d653ce493&_gl=1%2Aco46jl%2A_gcl_au%2AMTQ5MDg1ODk2Mi4xNzI5NzI2OTk2%2A_ga%2AMTI3MzEwNjUwMC4xNzI5NzI2OTk2%2A_ga_TJBEG291F0%2AMTczMTkwODYxOS44LjEuMTczMTkwOTUxOS4wLjAuMTIyODc2MjI1Mg.."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-90 transition-opacity"
                >
                  <img 
                    src="https://r2.hoefler-sbk.de/ecg.jpg.webp" 
                    alt="ECG Gütesiegel" 
                    className="w-[300px] h-[50px] object-contain"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
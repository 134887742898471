import React from 'react';
import { useParams } from 'react-router-dom';
import SEO from '../components/SEO';
import BlogPostContent from '../components/blog/BlogPostContent';
import { useBlogPost } from '../hooks/useBlogPost';
import LoadingState from '../components/blog/LoadingState';
import ErrorState from '../components/blog/ErrorState';

export default function BlogPost() {
  const { slug } = useParams();
  const { post, relatedPosts, loading, error } = useBlogPost(slug);

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (!post) return <ErrorState error="Beitrag nicht gefunden" />;

  return (
    <>
      <SEO 
        title={`${post.title} | Höfler Schädlingsbekämpfung`}
        description={post.excerpt.replace(/<[^>]*>/g, '').slice(0, 160)}
      />
      <div className="pt-24 bg-gray-50">
        <BlogPostContent post={post} relatedPosts={relatedPosts} />
      </div>
    </>
  );
}
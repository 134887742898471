import { useState } from 'react';
import { sendContactForm } from '../utils/brevo';

interface FormData {
  name: string;
  company: string;
  email: string;
  phone: string;
  inquiryType: string;
  message: string;
  privacyConsent: boolean;
}

interface SubmitStatus {
  type: 'success' | 'error';
  message: string;
}

const initialFormData: FormData = {
  name: '',
  company: '',
  email: '',
  phone: '',
  inquiryType: '',
  message: '',
  privacyConsent: false
};

export function useContactForm() {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;

    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      await sendContactForm({
        ...formData,
        message: `
          Firma: ${formData.company}
          Grund der Anfrage: ${formData.inquiryType}
          
          ${formData.message}
        `
      });
      
      setSubmitStatus({
        type: 'success',
        message: 'Ihre Nachricht wurde erfolgreich gesendet. Wir werden uns in Kürze bei Ihnen melden.'
      });
      
      setFormData(initialFormData);
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: error instanceof Error ? error.message : 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    formData,
    submitStatus,
    isSubmitting,
    focusedField,
    handleSubmit,
    handleChange,
    setFocusedField
  };
}
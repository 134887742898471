import React from 'react';
import LocationSearch from './LocationSearch';

export default function LocationSearchModule() {
  return (
    <section className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <p className="text-gray-600 max-w-2xl mx-auto">
            Geben Sie Ihre Stadt oder Postleitzahl ein, um den nächstgelegenen Experten zu finden
          </p>
        </div>
        
        <div className="max-w-2xl mx-auto">
          <LocationSearch />
        </div>
      </div>
    </section>
  );
}
import { Bug, Bed, Rat, Bird, Shield, Building2, Droplets, Construction } from 'lucide-react';

export const serviceItems = [
  {
    path: '/schaedlingsbekaempfung',
    label: 'Schädlingsbekämpfung',
    description: 'Professionelle Bekämpfung von Gesundheits- & Vorratsschädlingen',
    icon: Bug
  },
  {
    path: '/bettwanzenbekaempfung',
    label: 'Bettwanzenbekämpfung',
    description: 'Thermische Bettwanzenbekämpfung mit 100% Erfolgsgarantie',
    icon: Bed
  },
  {
    path: '/wespenbekaempfung',
    label: 'Wespenbekämpfung',
    description: 'Schnelle & fachgerechte Wespennestentfernung',
    icon: Bug
  },
  {
    path: '/rattenbekaempfung',
    label: 'Rattenbekämpfung',
    description: 'Nachhaltige Bekämpfung & Prävention von Nagetieren',
    icon: Rat
  },
  {
    path: '/taubenabwehr',
    label: 'Taubenabwehr',
    description: 'Professionelle Taubenabwehrsysteme & Vergrämung',
    icon: Bird
  },
  {
    path: '/taubenkotreinigung',
    label: 'Taubenkotreinigung',
    description: 'Fachgerechte Reinigung & Desinfektion',
    icon: Droplets
  },
  {
    path: '/holzschutz',
    label: 'Holzschutz',
    description: 'Bekämpfung holzzerstörender Insekten nach DIN',
    icon: Construction
  },
  {
    path: '/schaedlingsmonitoring',
    label: 'HACCP Monitoring',
    description: 'Professionelles Monitoring nach HACCP-Standards',
    icon: Shield
  }
];
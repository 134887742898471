import { useState, useRef, useCallback } from 'react';

interface UseMenuHoverOptions {
  openDelay?: number;
  closeDelay?: number;
}

export function useMenuHover({ 
  openDelay = 100, 
  closeDelay = 400 
}: UseMenuHoverOptions = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const mouseIsOverRef = useRef(false);

  const clearTimeouts = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  const handleMouseEnter = useCallback(() => {
    clearTimeouts();
    mouseIsOverRef.current = true;
    timeoutRef.current = setTimeout(() => {
      if (mouseIsOverRef.current) {
        setIsOpen(true);
      }
    }, openDelay);
  }, [openDelay]);

  const handleMouseLeave = useCallback(() => {
    clearTimeouts();
    mouseIsOverRef.current = false;
    timeoutRef.current = setTimeout(() => {
      if (!mouseIsOverRef.current) {
        setIsOpen(false);
      }
    }, closeDelay);
  }, [closeDelay]);

  return {
    isOpen,
    handlers: {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave
    }
  };
}
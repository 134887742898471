import React from 'react';
import SEO from '../components/SEO';

export default function Privacy() {
  return (
    <>
      <SEO 
        title="Datenschutzerklärung | Höfler Schädlingsbekämpfung"
        description="Datenschutzerklärung der Höfler Schädlingsbekämpfung. Informationen zur Verarbeitung Ihrer personenbezogenen Daten."
      />
      
      <div className="py-20 bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-2xl shadow-lg p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Datenschutzerklärung</h1>
            
            <div className="prose max-w-none">
              <p className="text-gray-600 mb-6">
                In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung 
                im Rahmen unserer Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der 
                gesetzlichen Bestimmungen (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003).
              </p>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Zugriffsdaten</h2>
              <p className="text-gray-600 mb-6">
                Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen wird Ihre IP-Adresse, 
                Beginn sowie Beginn und Ende der Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein 
                berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.
              </p>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Kontakt mit uns</h2>
              <p className="text-gray-600 mb-6">
                Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite, oder per Email kontaktieren, 
                dann werden die von Ihnen an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den 
                Fall von weiteren Anschlussfragen für sechs Monate bei uns gespeichert. Es erfolgt, ohne Ihre 
                Einwilligung, keine Weitergabe Ihrer übermittelten Daten.
              </p>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Cookies</h2>
              <p className="text-gray-600 mb-6">
                Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit 
                Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen 
                Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät 
                gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch 
                wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er 
                Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der 
                Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
              </p>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Ihre Rechte als Betroffener</h2>
              <p className="text-gray-600 mb-4">
                Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein Recht auf:
              </p>
              <ul className="list-disc pl-5 text-gray-600 space-y-1 mb-6">
                <li>Auskunft</li>
                <li>Löschung der Daten</li>
                <li>Berichtigung der Daten</li>
                <li>Übertragbarkeit der Daten</li>
                <li>Wiederruf und Widerspruch zur Datenverarbeitung</li>
                <li>Einschränkung</li>
              </ul>
              <p className="text-gray-600 mb-6">
                Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das Datenschutzrecht 
                passiert sind, so haben Sie die Möglichkeit sich bei uns (office@hoefler-sbk.eu) oder der 
                Datenschutzbehörde zu beschweren.
              </p>

              <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Kontaktdaten</h2>
              <p className="text-gray-600">
                Sie erreichen uns unter folgenden Kontaktdaten:<br />
                <strong>Webseitenbetreiber:</strong> Höfler Schädlingsbekämpfung<br />
                <strong>Telefonnummer:</strong> +43 (0) 720-317724<br />
                <strong>Email:</strong> office@hoefler-sbk.eu
              </p>

              <p className="text-sm text-gray-500 mt-8">
                Quelle: Datenschutzgenerator Österreich DSGVO
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
import React from 'react';
import SEO from '../components/SEO';
import HeadlessBlog from '../components/blog/HeadlessBlog';

export default function Blog() {
  return (
    <>
      <SEO 
        title="Blog & Schädlingslexikon | Höfler Schädlingsbekämpfung"
        description="Aktuelle Beiträge und Fachwissen rund um Schädlingsbekämpfung. Informative Artikel und praktische Tipps von IHK-zertifizierten Experten."
      />
      
      <div className="pt-24 bg-gray-50">
        {/* Hero Section */}
        <div className="relative bg-gradient-to-br from-[#0C2952] to-gray-900 py-16">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1455849318743-b2233052fcff?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              Blog & Schädlingslexikon
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Aktuelle Beiträge und Fachwissen rund um Schädlingsbekämpfung. 
              Informative Artikel und praktische Tipps von unseren Experten.
            </p>
          </div>
        </div>

        {/* Blog Content */}
        <HeadlessBlog />
      </div>
    </>
  );
}
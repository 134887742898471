import { PageTitle } from '../types/seo';

export const pageTitles: PageTitle[] = [
  {
    path: '/',
    title: 'Schädlingsbekämpfung Bayern & Oberösterreich | IHK-geprüft ✓',
    description: 'Ihr zertifizierter Partner für Schädlingsbekämpfung in Bayern & Oberösterreich. IHK-geprüft, professionelle Beratung, HACCP-konform. ✓ Kostenlose Erstberatung',
    priority: 1
  },
  {
    path: '/schaedlingsbekaempfung',
    title: 'Schädlingsbekämpfung | Professionell & Nachhaltig | Höfler',
    description: 'Professionelle Schädlingsbekämpfung für Privat- & Geschäftskunden. IHK-zertifizierte Experten, nachhaltige Methoden ✓ Festpreisgarantie ➤ Jetzt anfragen!',
    priority: 1
  },
  {
    path: '/bettwanzenbekaempfung',
    title: 'Bettwanzenbekämpfung mit Thermoverfahren | 100% Garantie ✓',
    description: 'Effektive Bettwanzenbekämpfung ohne Gift durch Thermoverfahren. 100% Erfolgsgarantie ✓ Diskrete Durchführung ➤ Kostenlose Erstberatung!',
    priority: 1
  },
  {
    path: '/wespenbekaempfung',
    title: 'Wespenbekämpfung & Umsiedlung | Naturschonend | Höfler',
    description: 'Professionelle & naturschonende Wespenbekämpfung durch Experten. Artgerechte Umsiedlung ✓ Festpreisgarantie ✓ Sofort-Hilfe ➤ Jetzt anfragen!',
    priority: 2
  },
  {
    path: '/rattenbekaempfung',
    title: 'Rattenbekämpfung & Vorsorge | Nachhaltige Lösungen ✓',
    description: 'Professionelle Rattenbekämpfung & dauerhafte Vorsorge. IHK-zertifizierte Experten ✓ Nachhaltige Methoden ➤ Jetzt kostenlos beraten lassen!',
    priority: 2
  },
  {
    path: '/taubenabwehr',
    title: 'Taubenabwehr & Vergrämung | Professionelle Lösungen ✓',
    description: 'Effektive Taubenabwehr & tierschutzgerechte Vergrämung. Dauerhafte Lösungen für Gebäudeschutz ✓ Festpreisgarantie ➤ Jetzt anfragen!',
    priority: 2
  },
  {
    path: '/taubenkotreinigung',
    title: 'Taubenkotreinigung & Desinfektion | Professionell & Gründlich',
    description: 'Professionelle Taubenkotreinigung & Desinfektion. Fachgerechte Entsorgung ✓ Vorbeugender Schutz ✓ Festpreisgarantie ➤ Jetzt anfragen!',
    priority: 2
  },
  {
    path: '/schaedlingsmonitoring',
    title: 'HACCP-Monitoring & Dokumentation | IHK-zertifiziert ✓',
    description: 'Professionelles HACCP-Schädlingsmonitoring mit digitaler Dokumentation. Online-Zugriff ✓ IHK-zertifiziert ✓ Beratung anfordern!',
    priority: 1
  },
  {
    path: '/holzschutz',
    title: 'Holzschutz nach DIN 68800-4 | Höfler Schädlingsbekämpfung',
    description: 'Fachgerechter Holzschutz nach DIN 68800-4. Bekämpfung holzzerstörender Insekten ✓ Werterhalt Ihrer Immobilie ➤ Kostenlose Begutachtung!',
    priority: 2
  },
  {
    path: '/leichenfundortreinigung',
    title: 'Leichenfundortreinigung | Diskret & Professionell | Höfler',
    description: 'Professionelle & pietätvolle Leichenfundortreinigung. Diskrete Durchführung ✓ Zertifizierte Experten ✓ Schnelle Hilfe ➤ Jetzt kontaktieren!',
    priority: 2
  },
  {
    path: '/ueber-uns',
    title: 'Über uns | Höfler Schädlingsbekämpfung seit 2008',
    description: 'Ihr Partner für Schädlingsbekämpfung seit 2008. IHK-geprüfte Experten ✓ Modernste Methoden ✓ Über 15 Jahre Erfahrung ➤ Lernen Sie uns kennen!',
    priority: 3
  },
  {
    path: '/kontakt',
    title: 'Kontakt | Höfler Schädlingsbekämpfung | Schnelle Hilfe',
    description: 'Professionelle Schädlingsbekämpfung in Bayern & Oberösterreich. Kostenlose Erstberatung ✓ Schnelle Hilfe ✓ Festpreisgarantie ➤ Jetzt kontaktieren!',
    priority: 3
  },
  {
    path: '/impressum',
    title: 'Impressum | Höfler Schädlingsbekämpfung',
    description: 'Impressum und rechtliche Informationen der Höfler Schädlingsbekämpfung. Ihr zertifizierter Partner für Schädlingsbekämpfung in Bayern & Oberösterreich.',
    priority: 4
  },
  {
    path: '/datenschutz',
    title: 'Datenschutzerklärung | Höfler Schädlingsbekämpfung',
    description: 'Datenschutzerklärung der Höfler Schädlingsbekämpfung. Informationen zur Verarbeitung Ihrer personenbezogenen Daten gemäß DSGVO.',
    priority: 4
  },
  {
    path: '/sitemap',
    title: 'Sitemap | Höfler Schädlingsbekämpfung',
    description: 'Übersichtliche Darstellung aller Services und Standorte der Höfler Schädlingsbekämpfung. Finden Sie schnell die gewünschten Informationen.',
    priority: 4
  }
];
import React from 'react';
import { Link } from 'react-router-dom';
import { RelatedPost } from '../../types/blog';

interface RelatedPostsProps {
  posts: RelatedPost[];
}

export default function RelatedPosts({ posts }: RelatedPostsProps) {
  if (!posts.length) return null;

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold text-[#0C2952] mb-4">
        Weitere Beiträge
      </h2>
      <div className="space-y-4">
        {posts.map(post => (
          <Link
            key={post.slug}
            to={`/blog/${post.slug}`}
            className="block hover:bg-gray-50 p-2 rounded transition-colors"
          >
            <h3 
              className="font-medium text-[#0C2952]"
              dangerouslySetInnerHTML={{ __html: post.title }}
            />
            <time className="text-sm text-gray-500">
              {new Date(post.date).toLocaleDateString('de-DE')}
            </time>
          </Link>
        ))}
      </div>
    </div>
  );
}
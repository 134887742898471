import React from 'react';
import LocationTemplate from '../pages/LocationTemplate';
import { serviceAreas } from './locations';

// Generate routes for each location
const generateLocationRoutes = () => {
  const routes = [];

  Object.entries(serviceAreas).forEach(([country, regions]) => {
    Object.entries(regions).forEach(([region, cities]) => {
      cities.forEach((city) => {
        routes.push({
          path: `/dienstleistungen/schaedlingsbekaempfung-${city.slug}`,
          component: () => (
            <LocationTemplate
              city={city.city}
              slug={city.slug}
              phone={city.phone}
              districts={city.districts}
              region={region}
            />
          )
        });
      });
    });
  });

  return routes;
};

export const locationRoutes = generateLocationRoutes();
import React from 'react';

interface FormFieldProps {
  label: string;
  name: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
  focused: boolean;
  required?: boolean;
}

export default function FormField({
  label,
  name,
  type = 'text',
  value,
  onChange,
  onFocus,
  onBlur,
  focused,
  required
}: FormFieldProps) {
  const isTextarea = type === 'textarea';
  const Component = isTextarea ? 'textarea' : 'input';

  return (
    <div className="relative">
      <label 
        htmlFor={name} 
        className={`absolute left-4 transition-all duration-200 ${
          focused || value 
            ? '-top-2.5 text-sm text-[#e16b28] bg-white px-2 z-10' 
            : 'top-3.5 text-gray-500'
        }`}
      >
        {label}
        {required && <span className="text-[#e16b28] ml-1">*</span>}
      </label>
      <Component
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`w-full px-4 rounded-xl border-2 border-gray-200 bg-gray-50 focus:bg-white focus:border-[#e16b28] focus:ring focus:ring-[#e16b28]/20 transition-all duration-200 ${
          isTextarea ? 'p-4 h-32 resize-none' : 'h-14'
        }`}
        required={required}
        rows={isTextarea ? 4 : undefined}
      />
    </div>
  );
}
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/layout/Layout';
import SEO from './components/SEO';
import { routes } from './routes';

export default function App() {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <SEO />
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}
import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Wrench, FileText, CheckCircle, AlertTriangle, Home, Building2, Ruler, Microscope, Leaf } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function Holzschutz() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Holzschutz DIN 68800-4 | Hausbockbekämpfung im Dachstuhl"
        description="Professionelle Holzschädlingsbekämpfung nach DIN 68800-4 ✓ Hausbockbekämpfung ✓ Zertifizierte Experten ➤ Kostenlose Erstberatung!"
        keywords="Holzschutz, DIN 68800-4, Hausbock, Dachstuhl, Holzschädlinge"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1615976955665-b4ff23d04d00?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Holzschutz nach DIN 68800-4
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Professionelle Bekämpfung von holzzerstörenden Insekten und Pilzen. 
              Zertifizierte Experten für nachhaltige Holzschutzmaßnahmen.
            </p>
            <div className="flex flex-wrap gap-4">
              {[
                { icon: Shield, text: 'DIN 68800-4 konform' },
                { icon: Wrench, text: 'Modernste Technik' },
                { icon: FileText, text: 'Gutachten & Dokumentation' }
              ].map((item, idx) => (
                <div key={idx} className="flex items-center bg-white/10 px-4 py-2 rounded-full">
                  <item.icon className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-white">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.section>

      {/* Gefahren-Box */}
      <div className="relative -mt-10 z-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="bg-white rounded-xl shadow-xl p-6 border-l-4 border-[#e16b28]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-xl font-bold text-gray-900 mb-4">Gefahren durch Holzschädlinge:</h2>
            <div className="grid md:grid-cols-3 gap-4">
              {[
                'Substanzverlust der Holzkonstruktion',
                'Statische Beeinträchtigung',
                'Wertminderung der Immobilie',
                'Ausbreitung des Befalls',
                'Hohe Sanierungskosten',
                'Gesundheitsgefährdung durch Pilze'
              ].map((danger, idx) => (
                <div key={idx} className="flex items-center bg-gray-50 p-3 rounded-lg">
                  <AlertTriangle className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-gray-700">{danger}</span>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Services Grid */}
          <motion.div 
            className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {[
              {
                icon: Microscope,
                title: 'Befallsanalyse',
                points: [
                  'Gründliche Inspektion',
                  'Artbestimmung',
                  'Befallsumfang ermitteln',
                  'Dokumentation'
                ]
              },
              {
                icon: Wrench,
                title: 'Bekämpfung',
                points: [
                  'DIN-konforme Methoden',
                  'Heißluftverfahren',
                  'Chemische Behandlung',
                  'Vorbeugende Maßnahmen'
                ]
              },
              {
                icon: Shield,
                title: 'Qualitätssicherung',
                points: [
                  'Erfolgskontrolle',
                  'Gutachten',
                  'Gewährleistung',
                  'Nachbetreuung'
                ]
              }
            ].map((service, idx) => (
              <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                <service.icon className="h-12 w-12 text-[#e16b28] mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <ul className="space-y-3">
                  {service.points.map((point, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </motion.div>

          {/* Anwendungsbereiche */}
          <motion.div 
            className="bg-white rounded-2xl shadow-xl p-8 mb-16"
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Anwendungsbereiche</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  icon: Home,
                  title: 'Privathäuser',
                  areas: [
                    'Dachstühle',
                    'Fachwerk',
                    'Holzbalkendecken',
                    'Terrassen'
                  ]
                },
                {
                  icon: Building2,
                  title: 'Gewerbeobjekte',
                  areas: [
                    'Industriehallen',
                    'Lagerhallen',
                    'Historische Gebäude',
                    'Öffentliche Bauten'
                  ]
                }
              ].map((category, idx) => (
                <div key={idx} className="bg-gray-50 rounded-xl p-6">
                  <div className="flex items-center mb-4">
                    <category.icon className="h-6 w-6 text-[#e16b28] mr-3" />
                    <h3 className="text-xl font-semibold text-gray-900">{category.title}</h3>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    {category.areas.map((area, i) => (
                      <div key={i} className="flex items-center bg-white p-3 rounded-lg">
                        <CheckCircle className="h-4 w-4 text-[#e16b28] mr-2" />
                        <span className="text-gray-700">{area}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>

          {/* DIN Standards */}
          <motion.div 
            className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8 text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-2xl font-bold mb-6">DIN 68800-4 Standards</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  title: 'Bekämpfungsmaßnahmen',
                  points: [
                    'Fachgerechte Durchführung',
                    'Geprüfte Wirkstoffe',
                    'Dokumentierte Prozesse'
                  ]
                },
                {
                  title: 'Qualifikationen',
                  points: [
                    'Zertifizierte Fachkräfte',
                    'Regelmäßige Schulungen',
                    'Aktuelle Sachkunde'
                  ]
                },
                {
                  title: 'Nachhaltigkeit',
                  points: [
                    'Umweltverträgliche Methoden',
                    'Minimaler Chemikalieneinsatz',
                    'Langfristiger Schutz'
                  ]
                }
              ].map((standard, idx) => (
                <div key={idx} className="space-y-4">
                  <h3 className="font-semibold text-lg flex items-center">
                    <Shield className="h-5 w-5 text-[#e16b28] mr-2" />
                    {standard.title}
                  </h3>
                  <ul className="space-y-2">
                    {standard.points.map((point, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <CheckCircle className="h-4 w-4 text-[#e16b28] mr-2" />
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
}
import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  {
    image: "https://r2.hoefler-sbk.de/Bilder_Webseite/sbk.jpeg.webp",
    title: 'Schädlingsbekämpfung',
    description: 'Professionelle Bekämpfung von Vorratsschädlingen, Gesundheitsschädlingen und Materialschädlingen durch IHK-zertifizierte Experten.',
    link: '/schaedlingsbekaempfung'
  },
  {
    image: "https://r2.hoefler-sbk.de/Bilder_Webseite/wanze.jpeg.webp",
    title: 'Bettwanzenbekämpfung',
    description: 'Thermische Bettwanzenbekämpfung mit 100% Erfolgsgarantie - ohne Insektizide und Chemikalien.',
    link: '/bettwanzenbekaempfung'
  },
  {
    image: "https://r2.hoefler-sbk.de/Bilder_Webseite/holzschutz.jpg",
    title: 'Holzschutz DIN68800-4',
    description: 'Fachgerechte Bekämpfung von holzzerstörenden Insekten nach DIN 68800 - für den Werterhalt Ihrer Immobilie.',
    link: '/holzschutz'
  },
  {
    image: "https://r2.hoefler-sbk.de/Bilder_Webseite/monitoring.jpg",
    title: 'HACCP-Monitoring',
    description: 'Professionelles Schädlingsmonitoring nach HACCP-Standards mit digitaler Dokumentation für maximale Lebensmittelsicherheit.',
    link: '/schaedlingsmonitoring'
  },
  {
    image: "https://r2.hoefler-sbk.de/Bilder_Webseite/wespennestentfernuung.jpg",
    title: 'Wespenbekämpfung',
    description: 'Schnelle und fachgerechte Wespennestentfernung durch zertifizierte Experten - 24/7 Notdienst verfügbar.',
    link: '/wespenbekaempfung'
  },
  {
    image: "https://r2.hoefler-sbk.de/Bilder_Webseite/taubenabwehr.jpg",
    title: 'Taubenabwehr',
    description: 'Nachhaltige Taubenabwehrsysteme und professionelle Taubenvergrämung für dauerhaften Gebäudeschutz.',
    link: '/taubenabwehr'
  }
];

export default function Services() {
  return (
    <section id="services" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
            Unsere Leistungen
          </h2>
          <p className="text-xl text-gray-600 mb-12">
            Professionelle Schädlingsbekämpfung für Privat- und Geschäftskunden
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <Link 
              key={index} 
              to={service.link}
              className="group bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden"
            >
              <div className="relative h-48">
                <img
                  src={service.image}
                  alt={`${service.title} - Höfler Schädlingsbekämpfung`}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                <h3 className="absolute bottom-4 left-4 text-xl font-semibold text-white">
                  {service.title}
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-600 mb-4">
                  {service.description}
                </p>
                <div className="flex items-center text-[#e16b28] font-medium group-hover:text-[#c85d22] transition-colors">
                  Mehr erfahren
                  <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
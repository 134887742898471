import React from 'react';
import parse from 'html-react-parser';
import type { ParsedContent } from '../../utils/blog/parser';

interface ContentProps {
  content: string;
  className?: string;
  onParsed?: (data: ParsedContent) => void;
}

export default function Content({ content, className = '', onParsed }: ContentProps) {
  const [parsedHtml, setParsedHtml] = React.useState(content);

  React.useEffect(() => {
    try {
      // Parse HTML with custom options
      const parsed = parse(content, {
        replace: (domNode: any) => {
          if (domNode.type === 'tag') {
            // Add classes based on tag type
            switch (domNode.name) {
              case 'p':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'mb-6 leading-relaxed text-gray-600'
                  }
                };
              case 'h2':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'text-2xl font-bold text-gray-900 mt-8 mb-4'
                  }
                };
              case 'h3':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'text-xl font-semibold text-gray-900 mt-6 mb-3'
                  }
                };
              case 'ul':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'list-disc pl-6 mb-6 space-y-2'
                  }
                };
              case 'ol':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'list-decimal pl-6 mb-6 space-y-2'
                  }
                };
              case 'li':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'text-gray-600 ml-4'
                  }
                };
              case 'blockquote':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'border-l-4 border-[#e16b28] pl-4 italic my-6 text-gray-600'
                  }
                };
              case 'img':
                return {
                  ...domNode,
                  attribs: {
                    ...domNode.attribs,
                    class: 'rounded-lg shadow-md my-6 max-w-full h-auto',
                    loading: 'lazy'
                  }
                };
            }
          }
          return domNode;
        }
      });

      setParsedHtml(parsed as string);
    } catch (error) {
      console.error('Error parsing content:', error);
      setParsedHtml(content);
    }
  }, [content]);

  return (
    <div className={`prose max-w-none ${className}`}>
      {parsedHtml}
    </div>
  );
}
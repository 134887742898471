import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { BlogPost, RelatedPost } from '../../types/blog';
import Content from './Content';
import RelatedPosts from './RelatedPosts';

interface BlogPostContentProps {
  post: BlogPost;
  relatedPosts: RelatedPost[];
}

export default function BlogPostContent({ post, relatedPosts }: BlogPostContentProps) {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main Content */}
        <motion.article 
          className="lg:w-[70%]"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="bg-white rounded-lg shadow-md">
            {post.featuredImage?.node && (
              <div className="aspect-video w-full overflow-hidden">
                <img
                  src={post.featuredImage.node.sourceUrl}
                  alt={post.featuredImage.node.altText || ''}
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            <div className="p-8">
              <Link 
                to="/blog"
                className="inline-flex items-center text-[#0C2952] hover:text-blue-700 mb-6"
              >
                <ArrowLeft className="h-4 w-4 mr-2" />
                Zurück zur Übersicht
              </Link>

              <h1 
                className="text-3xl font-bold text-[#0C2952] mb-4"
                dangerouslySetInnerHTML={{ __html: post.title }}
              />
              
              <div className="flex gap-4 text-sm text-gray-500 mb-6">
                <time dateTime={post.date}>
                  {new Date(post.date).toLocaleDateString('de-DE', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </time>
                <div className="flex gap-2">
                  {post.categories.nodes.map(cat => (
                    <span key={cat.slug} className="text-[#0C2952]">
                      {cat.name}
                    </span>
                  ))}
                </div>
              </div>

              <Content content={post.content} />
            </div>
          </div>
        </motion.article>

        {/* Sidebar */}
        <aside className="lg:w-[30%]">
          <RelatedPosts posts={relatedPosts} />
        </aside>
      </div>
    </div>
  );
}
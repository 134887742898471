import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Post } from '../../types/blog';

interface PostCardProps {
  post: Post;
  index: number;
}

export default function PostCard({ post, index }: PostCardProps) {
  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-all duration-300"
    >
      {post.featuredImage?.node && (
        <div className="aspect-video overflow-hidden">
          <img
            src={post.featuredImage.node.sourceUrl}
            alt={post.featuredImage.node.altText || ''}
            className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
            loading="lazy"
          />
        </div>
      )}
      <div className="p-6">
        <h3 
          className="text-xl font-semibold text-[#0C2952] mb-2"
          dangerouslySetInnerHTML={{ __html: post.title }}
        />
        <div className="text-sm text-gray-500 mb-3">
          {new Date(post.date).toLocaleDateString('de-DE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
          className="text-gray-600 text-base mb-4 leading-relaxed line-clamp-3"
        />
        <a
          href={`/blog/${post.slug}`}
          className="inline-flex items-center text-[#0C2952] hover:text-blue-700 font-medium transition-colors duration-200"
        >
          Weiterlesen
          <ArrowRight className="ml-2 h-4 w-4" />
        </a>
      </div>
    </motion.article>
  );
}
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import Contact from '../pages/Contact';
import Privacy from '../pages/Privacy';
import Impressum from '../pages/Impressum';

export const mainRoutes = [
  { path: '/', component: Home },
  { path: '/ueber-uns', component: AboutUs },
  { path: '/kontakt', component: Contact },
  { path: '/datenschutz', component: Privacy },
  { path: '/impressum', component: Impressum }
];
import { getCLS, getFID, getLCP } from 'web-vitals';

export const reportWebVitals = (onPerfEntry?: (metric: any) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getLCP(onPerfEntry);
  }
};

export const lazyLoadImage = (image: HTMLImageElement) => {
  if ('loading' in HTMLImageElement.prototype) {
    image.loading = 'lazy';
  }
};

export const preloadImage = (src: string) => {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = src;
  document.head.appendChild(link);
};

export const preloadCriticalAssets = () => {
  const criticalImages = [
    'https://r2.hoefler-sbk.de/logo_clean.png'
  ];

  criticalImages.forEach(preloadImage);
};
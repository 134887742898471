import React from 'react';
import { motion } from 'framer-motion';
import { MapPin, Phone, Shield, CheckCircle, Star, Leaf } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';
import Contact from '../components/Contact';

interface LocationTemplateProps {
  city: string;
  slug: string;
  phone: string;
  districts: string[];
  region: string;
}

export default function LocationTemplate({ 
  city, 
  slug, 
  phone, 
  districts, 
  region 
}: LocationTemplateProps) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title={`Schädlingsbekämpfung ${city} | IHK-geprüft ✓ Festpreisgarantie`}
        description={`Professionelle Schädlingsbekämpfung in ${city} ✓ 24/7 Service ✓ IHK-zertifiziert ✓ Festpreisgarantie ➤ Jetzt kostenlos beraten lassen!`}
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <div className="flex items-center mb-4 text-[#e16b28]">
              <MapPin className="h-5 w-5 mr-2" />
              <span className="text-lg">{city} & Umgebung</span>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Schädlingsbekämpfung {city}
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Ihr zertifizierter Partner für professionelle und nachhaltige Schädlingsbekämpfung in {city}. 
              Kompetente Beratung und maßgeschneiderte Lösungen.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a 
                href={`tel:${phone}`}
                className="inline-flex items-center justify-center px-6 py-3 bg-[#e16b28] text-white rounded-lg hover:bg-[#c85d22] transition-colors"
              >
                <Phone className="h-5 w-5 mr-2" />
                {phone}
              </a>
              <button 
                onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
                className="inline-flex items-center justify-center px-6 py-3 border-2 border-white text-white rounded-lg hover:bg-white hover:text-gray-900 transition-colors"
              >
                Kostenlose Beratung
              </button>
            </div>
          </div>
        </div>
      </motion.section>

      {/* USPs */}
      <motion.div 
        className="bg-white py-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {[
              { icon: Shield, text: 'IHK-geprüft' },
              { icon: Star, text: 'Festpreisgarantie' },
              { icon: Leaf, text: 'Nachhaltige Methoden' },
              { icon: Phone, text: 'Schneller Service' }
            ].map((item, idx) => (
              <div key={idx} className="flex items-center justify-center p-4 bg-gray-50 rounded-lg">
                <item.icon className="h-5 w-5 text-[#e16b28] mr-2" />
                <span className="text-sm font-medium">{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Service Areas */}
          <motion.div 
            className="bg-white rounded-2xl shadow-xl p-8 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Einsatzgebiet {city} & Umgebung
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {districts.map((district, idx) => (
                <div key={idx} className="flex items-center bg-gray-50 p-3 rounded-lg">
                  <MapPin className="h-4 w-4 text-[#e16b28] mr-2" />
                  <span className="text-sm">{district}</span>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Content */}
          <motion.div 
            className="bg-white rounded-2xl shadow-xl p-8"
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Ihr Experte für Schädlingsbekämpfung in {city}
            </h2>
            <div className="prose max-w-none text-gray-600">
              <p className="mb-6">
                Als führender Anbieter für Schädlingsbekämpfung in {city} bieten wir Ihnen 
                professionelle und nachhaltige Lösungen für alle Arten von Schädlingsproblemen. 
                Unser Team aus IHK-geprüften Schädlingsbekämpfern verbindet traditionelles 
                Handwerk mit modernster Technologie.
              </p>

              <h3 className="text-xl font-semibold text-gray-900 mb-4">Unsere Leistungen in {city}</h3>
              <ul className="grid md:grid-cols-2 gap-4 mb-8">
                {[
                  'Professionelle Schädlingsbekämpfung',
                  'Bettwanzenbekämpfung',
                  'Wespenbekämpfung',
                  'Rattenbekämpfung',
                  'Taubenabwehr',
                  'HACCP-Monitoring'
                ].map((service, idx) => (
                  <li key={idx} className="flex items-center">
                    <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                    <span>{service}</span>
                  </li>
                ))}
              </ul>

              <p className="mb-6">
                Durch unsere lokale Präsenz in {city} kennen wir die spezifischen 
                Herausforderungen der Region und bieten maßgeschneiderte Lösungen für 
                Privathaushalte und Unternehmen. Dabei setzen wir auf umweltschonende 
                Verfahren und nachhaltige Konzepte für langfristigen Erfolg.
              </p>

              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <h3 className="text-xl font-semibold mb-4">Ihre Vorteile</h3>
                  <ul className="space-y-3">
                    {[
                      'Schnelle Reaktionszeiten',
                      'Festpreisgarantie',
                      'Modernste Methoden',
                      'Umweltschonende Verfahren',
                      'Ausführliche Dokumentation',
                      'Präventive Beratung'
                    ].map((advantage, idx) => (
                      <li key={idx} className="flex items-center">
                        <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                        <span>{advantage}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-4">Branchen</h3>
                  <ul className="space-y-3">
                    {[
                      'Gastronomie & Hotellerie',
                      'Lebensmittelproduktion',
                      'Lagerhallen & Logistik',
                      'Bürogebäude & Verwaltung',
                      'Einzelhandel',
                      'Privathaushalte'
                    ].map((industry, idx) => (
                      <li key={idx} className="flex items-center">
                        <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                        <span>{industry}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Contact Section */}
      <Contact />
    </>
  );
}
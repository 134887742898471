import React from 'react';
import { Link } from 'react-router-dom';
import { useHoverEffect } from '../../utils/hover/useHoverEffect';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function NavLink({ to, children, className = '', onClick }: NavLinkProps) {
  const getNavLinkProps = useHoverEffect('menu-item');
  const hoverProps = getNavLinkProps();

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`flex items-center px-4 py-2 text-base font-medium rounded-md ${className}`}
      style={{
        ...hoverProps.style,
        willChange: 'transform'
      }}
    >
      {children}
    </Link>
  );
}
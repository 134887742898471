import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export const useScrollToTopOnNavigate = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const constructUrl = (path: string): string => {
  const baseUrl = 'https://hoefler-sbk.eu';
  return new URL(path, baseUrl).toString();
};
export const isPWASupported = () => {
  const isStackBlitz = window.location.hostname.includes('stackblitz.io');
  const isDevelopment = process.env.NODE_ENV === 'development';
  
  const hasServiceWorker = 'serviceWorker' in navigator;
  const hasCache = 'caches' in window;
  const hasManifest = !!document.querySelector('link[rel="manifest"]');
  
  return {
    supported: !isStackBlitz && !isDevelopment && hasServiceWorker && hasCache,
    hasServiceWorker,
    hasCache,
    hasManifest,
    isStackBlitz,
    isDevelopment,
    environment: isStackBlitz ? 'stackblitz' : 'production'
  };
};
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useBlogPosts } from '../../hooks/useBlogPosts';
import PostCard from './PostCard';
import TabNavigation from './TabNavigation';
import LoadingState from './LoadingState';
import ErrorState from './ErrorState';
import EmptyState from './EmptyState';

export default function HeadlessBlog() {
  const [activeTab, setActiveTab] = useState('blog');
  const { posts, loading, error } = useBlogPosts();

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  const filteredPosts = posts.filter(post => 
    post.categories.nodes.some(cat => 
      activeTab === 'blog' ? cat.slug === 'blog' : cat.slug === 'schaedlingslexikon'
    )
  );

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <TabNavigation 
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />

      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {filteredPosts.map((post, index) => (
          <PostCard 
            key={post.id} 
            post={post} 
            index={index}
          />
        ))}
      </motion.div>
      
      {filteredPosts.length === 0 && <EmptyState />}
    </div>
  );
}
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { pageTitles } from '../data/seoTitles';
import { serviceKeywords, locationKeywords } from '../data/seoKeywords';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  url?: string;
  alternateUrls?: {
    de?: string;
    at?: string;
  };
}

const BASE_URL = 'https://hoefler-sbk.eu';

export default function SEO({
  title,
  description,
  keywords,
  url = BASE_URL,
  alternateUrls
}: SEOProps) {
  const currentPath = url.replace(BASE_URL, '').replace(/\/$/, '') || '/';
  const pageData = pageTitles.find(page => page.path === currentPath);

  const finalTitle = title || pageData?.title || 'Höfler Schädlingsbekämpfung';
  const finalDescription = description || pageData?.description || 'Professionelle Schädlingsbekämpfung in Bayern & Oberösterreich ✓ IHK-zertifiziert ✓ 15+ Jahre Erfahrung ✓ Festpreisgarantie ➤ Jetzt anfragen!';
  const finalKeywords = keywords || [...serviceKeywords, ...locationKeywords].join(', ');

  const canonicalUrl = url.startsWith('http') ? url : `${BASE_URL}${url.startsWith('/') ? url : `/${url}`}`;

  return (
    <Helmet>
      <title>{finalTitle}</title>
      <meta name="description" content={finalDescription} />
      <meta name="keywords" content={finalKeywords} />

      {/* Canonical & Hreflang Tags */}
      <link rel="canonical" href={canonicalUrl} />
      <link rel="alternate" href={alternateUrls?.de || canonicalUrl} hreflang="de-DE" />
      <link rel="alternate" href={alternateUrls?.at || canonicalUrl} hreflang="de-AT" />
      <link rel="alternate" href={canonicalUrl} hreflang="x-default" />

      {/* Open Graph Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={finalTitle} />
      <meta property="og:description" content={finalDescription} />
      <meta property="og:image" content="https://r2.hoefler-sbk.de/logo_clean.png" />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:locale:alternate" content="de_AT" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={finalTitle} />
      <meta name="twitter:description" content={finalDescription} />
      <meta name="twitter:image" content="https://r2.hoefler-sbk.de/logo_clean.png" />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="German" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content="Höfler Schädlingsbekämpfung" />
    </Helmet>
  );
}
import React from 'react';
import { Menu, X, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { serviceItems } from './serviceItems';

interface MobileNavProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function MobileNav({ isOpen, setIsOpen }: MobileNavProps) {
  const [openSubmenu, setOpenSubmenu] = React.useState<string | null>(null);

  return (
    <>
      <div className="md:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-[#e16b28] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#e16b28] transition-colors"
          aria-expanded={isOpen}
          aria-label={isOpen ? "Menü schließen" : "Menü öffnen"}
          aria-controls="mobile-menu"
        >
          {isOpen ? (
            <X className="block h-6 w-6" aria-hidden="true" />
          ) : (
            <Menu className="block h-6 w-6" aria-hidden="true" />
          )}
        </button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            id="mobile-menu"
            role="navigation"
            aria-label="Mobile Navigation"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="md:hidden fixed inset-x-0 top-24 bg-white border-t border-gray-200 z-50"
          >
            <div className="px-4 py-2 space-y-1">
              <Link
                to="/"
                className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-[#e16b28] hover:bg-gray-50 rounded-md"
                onClick={() => setIsOpen(false)}
                role="menuitem"
              >
                Startseite
              </Link>

              <div>
                <button
                  className="w-full flex items-center justify-between px-3 py-2 text-base font-medium text-gray-600 hover:text-[#e16b28] hover:bg-gray-50 rounded-md"
                  onClick={() => setOpenSubmenu(openSubmenu === 'services' ? null : 'services')}
                  aria-expanded={openSubmenu === 'services'}
                  aria-controls="services-submenu"
                  aria-label="Leistungen Menü"
                >
                  <span>Leistungen</span>
                  <ChevronDown 
                    className={`h-5 w-5 transition-transform ${
                      openSubmenu === 'services' ? 'rotate-180' : ''
                    }`}
                    aria-hidden="true"
                  />
                </button>

                <AnimatePresence>
                  {openSubmenu === 'services' && (
                    <motion.div
                      id="services-submenu"
                      role="menu"
                      aria-label="Leistungen Untermenü"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="pl-4"
                    >
                      {serviceItems.map((item) => (
                        <Link
                          key={item.path}
                          to={item.path}
                          className="block px-3 py-2 text-base text-gray-600 hover:text-[#e16b28] hover:bg-gray-50 rounded-md"
                          onClick={() => {
                            setIsOpen(false);
                            setOpenSubmenu(null);
                          }}
                          role="menuitem"
                        >
                          {item.label}
                        </Link>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <Link
                to="/ueber-uns"
                className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-[#e16b28] hover:bg-gray-50 rounded-md"
                onClick={() => setIsOpen(false)}
                role="menuitem"
              >
                Über Uns
              </Link>

              <Link
                to="/kontakt"
                className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-[#e16b28] hover:bg-gray-50 rounded-md"
                onClick={() => setIsOpen(false)}
                role="menuitem"
              >
                Kontakt
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
import React from 'react';
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useHoverEffect } from '../../utils/hover/useHoverEffect';
import { useMenuHover } from '../../utils/hover/useMenuHover';
import { serviceItems } from './serviceItems';

export default function DropdownMenu() {
  const { isOpen, handlers } = useMenuHover();
  const getNavLinkProps = useHoverEffect('menu-item');
  const hoverProps = getNavLinkProps();

  return (
    <div 
      className="relative"
      {...handlers}
    >
      <button
        className="flex items-center px-4 py-2 text-base font-medium rounded-md"
        style={{
          ...hoverProps.style,
          willChange: 'transform'
        }}
      >
        <span>Leistungen</span>
        <ChevronDown className={`ml-1.5 h-4 w-4 transition-transform duration-300 ${
          isOpen ? 'rotate-180' : ''
        }`} />
      </button>

      {isOpen && (
        <div 
          className="absolute left-0 w-[580px] bg-white rounded-xl shadow-xl border border-gray-100 mt-2 p-4 grid grid-cols-2 gap-3"
          onMouseEnter={handlers.onMouseEnter}
          onMouseLeave={handlers.onMouseLeave}
        >
          {serviceItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className="flex items-start p-3 rounded-lg hover:bg-gray-50 transition-colors group"
              onClick={() => setIsOpen(false)}
            >
              <div>
                <span className="font-medium text-gray-900 group-hover:text-[#e16b28] transition-colors">
                  {item.label}
                </span>
                <p className="text-sm text-gray-500 mt-0.5 line-clamp-2">
                  {item.description}
                </p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
import React from 'react';
import { Link } from 'react-router-dom';
import { useHoverEffect } from '../utils/hover/useHoverEffect';

interface LogoProps {
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isHovered?: boolean;
}

export default function Logo({ 
  className = '', 
  onMouseEnter, 
  onMouseLeave,
  isHovered = false 
}: LogoProps) {
  const getNavLinkProps = useHoverEffect('nav-link');

  return (
    <Link 
      to="/"
      className={`flex items-center text-gray-900 hover:text-[#e16b28] transition-colors ${className}`}
      {...getNavLinkProps()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img
        src="https://r2.hoefler-sbk.de/logo_clean.png"
        alt="Höfler Schädlingsbekämpfung Logo"
        className={`h-16 w-auto transition-transform duration-200 ${
          isHovered ? 'scale-110' : ''
        }`}
      />
      <span className="sr-only">Höfler Schädlingsbekämpfung</span>
    </Link>
  );
}
import React from 'react';
import Hero from '../components/Hero';
import Services from '../components/Services';
import WhyChooseUs from '../components/WhyChooseUs';
import LocationSearchModule from '../components/LocationSearchModule';
import BookingSection from '../components/BookingSection';

export default function Home() {
  return (
    <>
      <Hero />
      <LocationSearchModule />
      <Services />
      <BookingSection />
      <WhyChooseUs />
    </>
  );
}
import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="relative pt-16">
      <div className="bg-gradient-to-br from-gray-900 to-gray-800">
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 md:py-32">
          <div className="lg:w-2/3">
            <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-white mb-4 md:mb-6">
              Professionelle Schädlingsbekämpfung in Bayern & Oberösterreich
            </h1>
            <p className="text-lg md:text-xl text-gray-200 mb-6 md:mb-8">
              Ihr zertifizierter Partner für moderne und umweltbewusste Schädlingsbekämpfung mit über 15 Jahren Erfahrung. 
              Wir verbinden traditionelles Handwerk mit innovativer Technologie für nachhaltige Lösungen.
            </p>

            <div className="flex flex-col sm:flex-row gap-3 md:gap-4">
              <Link 
                to="/kontakt" 
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#e16b28] hover:bg-[#c85d22] transition-colors shadow-lg hover:shadow-xl"
              >
                Kostenlose Erstberatung
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <a 
                href="#services" 
                className="inline-flex items-center justify-center px-6 py-3 border-2 border-white text-base font-medium rounded-md text-white hover:bg-white hover:text-gray-900 transition-colors"
              >
                Unsere Leistungen
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
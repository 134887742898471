import React from 'react';

export default function LoadingState() {
  return (
    <div className="flex justify-center items-center min-h-[400px]">
      <div className="animate-pulse flex flex-col items-center">
        <div className="h-8 w-32 bg-gray-200 rounded mb-4"></div>
        <div className="text-[#0C2952]">Lade Beiträge...</div>
      </div>
    </div>
  );
}
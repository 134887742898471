import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, MapPin, Clock, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';

const regions = [
  {
    name: 'Oberbayern',
    url: 'https://cal.com/hoeflersbk/oby',
    description: 'München, Rosenheim, Ingolstadt und Umgebung'
  },
  {
    name: 'Niederbayern',
    url: 'https://cal.com/hoeflersbk/nby',
    description: 'Passau, Landshut, Deggendorf und Umgebung'
  },
  {
    name: 'Oberösterreich',
    url: 'https://cal.com/hoeflersbk/at',
    description: 'Linz, Wels, Steyr und Umgebung'
  }
];

export default function BookingSection() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
            Online Terminbuchung
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Vereinbaren Sie bequem online einen Termin für Ihre Region. Wählen Sie aus verfügbaren Zeiten und erhalten Sie eine sofortige Bestätigung.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {regions.map((region, idx) => (
            <motion.div
              key={region.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: idx * 0.1 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden"
            >
              <div className="bg-gradient-to-br from-gray-900 to-gray-800 p-6">
                <div className="flex items-center mb-4">
                  <MapPin className="h-5 w-5 text-[#e16b28] mr-2" />
                  <h3 className="text-xl font-semibold text-white">{region.name}</h3>
                </div>
                <p className="text-gray-300 text-sm">{region.description}</p>
              </div>

              <div className="p-6 space-y-4">
                <div className="flex items-center text-gray-600">
                  <Clock className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span>24/7 Online-Buchung</span>
                </div>

                <a
                  href={region.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center w-full px-6 py-3 bg-[#e16b28] text-white rounded-lg hover:bg-[#c85d22] transition-colors"
                >
                  <Calendar className="h-5 w-5 mr-2" />
                  Termin buchen
                </a>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-600 mb-4">
            Sie möchten lieber persönlich einen Termin vereinbaren?
          </p>
          <Link
            to="/kontakt"
            className="inline-flex items-center text-[#e16b28] hover:text-[#c85d22]"
          >
            <Phone className="h-5 w-5 mr-2" />
            Kontaktieren Sie uns
          </Link>
        </div>
      </div>
    </section>
  );
}
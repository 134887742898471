// Service Areas with their metadata
export const serviceAreas = {
  bayern: {
    oberbayern: [
      {
        city: 'München',
        slug: 'muenchen',
        phone: '+49(0)89-21544167',
        districts: ['München-Stadt', 'München-Land', 'Dachau', 'Freising', 'Erding', 'Fürstenfeldbruck'],
        zipCodes: ['80*', '81*', '82*', '85*']
      },
      {
        city: 'Rosenheim',
        slug: 'rosenheim',
        phone: '+49(0)89-21544167',
        districts: ['Rosenheim-Stadt', 'Rosenheim-Land', 'Miesbach', 'Traunstein'],
        zipCodes: ['83*']
      },
      {
        city: 'Ingolstadt',
        slug: 'ingolstadt',
        phone: '+49(0)89-21544167',
        districts: ['Ingolstadt', 'Pfaffenhofen a/d Ilm'],
        zipCodes: ['85*']
      },
      {
        city: 'Altötting',
        slug: 'altoetting',
        phone: '+49(0)89-21544167',
        districts: ['Altötting', 'Mühldorf am Inn'],
        zipCodes: ['84*']
      },
      {
        city: 'Starnberg',
        slug: 'starnberg',
        phone: '+49(0)89-21544167',
        districts: ['Starnberg', 'Weilheim-Schongau'],
        zipCodes: ['82*']
      },
      {
        city: 'Dachau',
        slug: 'dachau',
        phone: '+49(0)89-21544167',
        districts: ['Dachau', 'Dachau-Land'],
        zipCodes: ['85*']
      },
      {
        city: 'Erding',
        slug: 'erding',
        phone: '+49(0)89-21544167',
        districts: ['Erding', 'Erding-Land'],
        zipCodes: ['85*']
      },
      {
        city: 'Freising',
        slug: 'freising',
        phone: '+49(0)89-21544167',
        districts: ['Freising', 'Freising-Land'],
        zipCodes: ['85*']
      },
      {
        city: 'Fürstenfeldbruck',
        slug: 'fuerstenfeldbruck',
        phone: '+49(0)89-21544167',
        districts: ['Fürstenfeldbruck', 'Fürstenfeldbruck-Land'],
        zipCodes: ['82*']
      },
      {
        city: 'Miesbach',
        slug: 'miesbach',
        phone: '+49(0)89-21544167',
        districts: ['Miesbach', 'Miesbach-Land'],
        zipCodes: ['83*']
      },
      {
        city: 'Pfaffenhofen a/d Ilm',
        slug: 'pfaffenhofen',
        phone: '+49(0)89-21544167',
        districts: ['Pfaffenhofen a/d Ilm', 'Pfaffenhofen-Land'],
        zipCodes: ['85*']
      },
      {
        city: 'Traunstein',
        slug: 'traunstein',
        phone: '+49(0)89-21544167',
        districts: ['Traunstein', 'Traunstein-Land'],
        zipCodes: ['83*']
      }
    ],
    niederbayern: [
      {
        city: 'Passau',
        slug: 'passau',
        phone: '+49(0)8542-9699769',
        districts: ['Passau-Stadt', 'Passau-Land'],
        zipCodes: ['94*']
      },
      {
        city: 'Landshut',
        slug: 'landshut',
        phone: '+49(0)8542-9699769',
        districts: ['Landshut-Stadt', 'Landshut-Land'],
        zipCodes: ['84*']
      },
      {
        city: 'Deggendorf',
        slug: 'deggendorf',
        phone: '+49(0)8542-9699769',
        districts: ['Deggendorf', 'Regen', 'Freyung-Grafenau'],
        zipCodes: ['94*']
      },
      {
        city: 'Straubing',
        slug: 'straubing',
        phone: '+49(0)8542-9699769',
        districts: ['Straubing-Stadt', 'Straubing-Land', 'Dingolfing'],
        zipCodes: ['94*']
      },
      {
        city: 'Kelheim',
        slug: 'kelheim',
        phone: '+49(0)8542-9699769',
        districts: ['Kelheim', 'Abensberg', 'Mainburg'],
        zipCodes: ['93*']
      },
      {
        city: 'Pfarrkirchen',
        slug: 'pfarrkirchen',
        phone: '+49(0)8542-9699769',
        districts: ['Pfarrkirchen', 'Rottal-Inn'],
        zipCodes: ['84*']
      },
      {
        city: 'Cham',
        slug: 'cham',
        phone: '+49(0)8542-9699769',
        districts: ['Cham', 'Bad Kötzting', 'Furth im Wald'],
        zipCodes: ['93*']
      },
      {
        city: 'Dingolfing',
        slug: 'dingolfing',
        phone: '+49(0)8542-9699769',
        districts: ['Dingolfing', 'Dingolfing-Land'],
        zipCodes: ['84*']
      },
      {
        city: 'Freyung-Grafenau',
        slug: 'freyung-grafenau',
        phone: '+49(0)8542-9699769',
        districts: ['Freyung', 'Grafenau'],
        zipCodes: ['94*']
      },
      {
        city: 'Regen',
        slug: 'regen',
        phone: '+49(0)8542-9699769',
        districts: ['Regen', 'Regen-Land'],
        zipCodes: ['94*']
      }
    ],
    oberpfalz: [
      {
        city: 'Regensburg',
        slug: 'regensburg',
        phone: '+49(0)8542-9699769',
        districts: ['Regensburg-Stadt', 'Regensburg-Land'],
        zipCodes: ['93*']
      }
    ]
  },
  oesterreich: {
    oberoesterreich: [
      {
        city: 'Linz',
        slug: 'linz',
        phone: '+43(0)720-317724',
        districts: ['Linz-Stadt', 'Linz-Land'],
        zipCodes: ['40*']
      },
      {
        city: 'Wels',
        slug: 'wels',
        phone: '+43(0)720-317724',
        districts: ['Wels-Stadt', 'Wels-Land', 'Grieskirchen'],
        zipCodes: ['46*', '47*']
      },
      {
        city: 'Steyr',
        slug: 'steyr',
        phone: '+43(0)720-317724',
        districts: ['Steyr-Stadt', 'Steyr-Land'],
        zipCodes: ['44*']
      },
      {
        city: 'Braunau am Inn',
        slug: 'braunau-am-inn',
        phone: '+43(0)720-317724',
        districts: ['Braunau am Inn', 'Ried im Innkreis'],
        zipCodes: ['50*']
      },
      {
        city: 'Schärding',
        slug: 'schaerding',
        phone: '+43(0)720-317724',
        districts: ['Schärding', 'Eferding'],
        zipCodes: ['47*']
      },
      {
        city: 'Vöcklabruck',
        slug: 'voecklabruck',
        phone: '+43(0)720-317724',
        districts: ['Vöcklabruck', 'Gmunden'],
        zipCodes: ['48*']
      },
      {
        city: 'Rohrbach',
        slug: 'rohrbach',
        phone: '+43(0)720-317724',
        districts: ['Rohrbach', 'Urfahr-Umgebung'],
        zipCodes: ['41*']
      },
      {
        city: 'Eferding',
        slug: 'eferding',
        phone: '+43(0)720-317724',
        districts: ['Eferding', 'Eferding-Land'],
        zipCodes: ['47*']
      },
      {
        city: 'Grieskirchen',
        slug: 'grieskirchen',
        phone: '+43(0)720-317724',
        districts: ['Grieskirchen', 'Grieskirchen-Land'],
        zipCodes: ['47*']
      },
      {
        city: 'Ried im Innkreis',
        slug: 'ried-im-innkreis',
        phone: '+43(0)720-317724',
        districts: ['Ried im Innkreis', 'Ried-Land'],
        zipCodes: ['48*']
      }
    ]
  }
};
import { Post, BlogPost, RelatedPost } from '../../types/blog';

const BLOG_API_URL = 'https://blog.hoefler-sbk.eu/graphql';

export async function fetchPosts(): Promise<Post[]> {
  const query = `
    {
      posts {
        nodes {
          id
          title
          excerpt
          date
          slug
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `;

  const response = await fetch(BLOG_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  });

  const data = await response.json();
  
  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.posts.nodes;
}

export async function fetchPost(slug: string): Promise<{ post: BlogPost; relatedPosts: RelatedPost[] }> {
  const query = `
    query GetPost($slug: ID!) {
      post(id: $slug, idType: SLUG) {
        title
        content
        date
        slug
        excerpt
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
      posts(first: 5, where: { categoryName: "blog" }) {
        nodes {
          title
          slug
          date
        }
      }
    }
  `;

  const response = await fetch(BLOG_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      query,
      variables: { slug }
    }),
  });

  const data = await response.json();
  
  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return {
    post: data.data.post,
    relatedPosts: data.data.posts.nodes
  };
}
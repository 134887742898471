import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Bug, Building2, Home, CheckCircle, Star, Leaf, HeartPulse } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function Wespenbekaempfung() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Wespenbekämpfung & Wespennestentfernung | Naturschonend ✓"
        description="Professionelle Wespenbekämpfung & Wespennestentfernung ✓ Artgerechte Umsiedlung ✓ Festpreisgarantie ✓ IHK-zertifiziert ➤ Jetzt anfragen!"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1589874186480-ecd085096d45?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Professionelle Wespenbekämpfung & Wespennestentfernung
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Naturschonende und artgerechte Wespennestentfernung durch zertifizierte Experten
            </p>
            <div className="flex flex-wrap gap-4">
              {[
                { icon: Shield, text: 'IHK-geprüft' },
                { icon: Star, text: 'Festpreisgarantie' },
                { icon: Leaf, text: 'Naturschonend' }
              ].map((item, idx) => (
                <div key={idx} className="flex items-center bg-white/10 px-4 py-2 rounded-full">
                  <item.icon className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-white">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.section>

      {/* Main Content */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Services Grid */}
          <motion.div 
            className="grid md:grid-cols-3 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {[
              {
                icon: Bug,
                title: 'Professionelle Entfernung',
                points: [
                  'Fachgerechte Umsiedlung',
                  'Artgerechte Behandlung',
                  'Gesetzeskonforme Methoden',
                  'Nachhaltige Lösungen'
                ]
              },
              {
                icon: Shield,
                title: 'Präventive Maßnahmen',
                points: [
                  'Bauliche Beratung',
                  'Vorsorgende Maßnahmen',
                  'Langfristiger Schutz',
                  'Individuelle Lösungen'
                ]
              },
              {
                icon: HeartPulse,
                title: 'Sicherheit & Schutz',
                points: [
                  'Professionelle Ausrüstung',
                  'Geschultes Personal',
                  'Sichere Durchführung',
                  'Umfassende Beratung'
                ]
              }
            ].map((service, idx) => (
              <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                <div className="flex items-center mb-4">
                  <service.icon className="h-12 w-12 text-[#e16b28] mr-4" />
                  <h3 className="text-xl font-semibold text-gray-900">{service.title}</h3>
                </div>
                <ul className="space-y-2">
                  {service.points.map((point, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </motion.div>

          {/* Content Grid */}
          <div className="grid lg:grid-cols-3 gap-8">
            {/* Main Content - 2 Columns */}
            <div className="lg:col-span-2 space-y-8">
              <motion.div 
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  Professionelle Wespenbekämpfung mit Expertise
                </h2>
                
                <div className="prose max-w-none">
                  <p className="mb-4">
                    Als IHK-zertifizierte Experten für Wespenbekämpfung bieten wir Ihnen professionelle und naturschonende Lösungen für Wespenprobleme. Unsere erfahrenen Fachkräfte sind speziell geschult im Umgang mit Wespen und deren artgerechter Umsiedlung. Dabei achten wir besonders auf den Schutz geschützter Arten und befolgen alle gesetzlichen Vorschriften.
                  </p>

                  <h3 className="text-xl font-semibold mt-8 mb-4">Fachgerechte Wespennestentfernung</h3>
                  <p className="mb-4">
                    Die sichere und fachgerechte Entfernung von Wespennestern erfordert Expertise und professionelle Ausrüstung. Unsere Spezialisten führen zunächst eine genaue Analyse durch, um die Wespenart zu bestimmen und die optimale Vorgehensweise festzulegen. Je nach Situation und Art entscheiden wir über die geeignete Methode - von der Umsiedlung bis zur kontrollierten Entfernung.
                  </p>

                  <h3 className="text-xl font-semibold mt-8 mb-4">Präventive Maßnahmen und Beratung</h3>
                  <p className="mb-4">
                    Neben der akuten Wespenbekämpfung bieten wir auch umfassende Beratung zur Prävention an. Dies umfasst bauliche Maßnahmen zur Vermeidung von Wespennestern sowie Verhaltensempfehlungen für den richtigen Umgang mit Wespen. Unsere präventiven Lösungen helfen, zukünftige Probleme zu vermeiden und ein harmonisches Zusammenleben mit der Natur zu ermöglichen.
                  </p>

                  <h3 className="text-xl font-semibold mt-8 mb-4">Sicherheit hat höchste Priorität</h3>
                  <p>
                    Bei der Wespenbekämpfung steht die Sicherheit an erster Stelle. Unsere Mitarbeiter sind mit modernster Schutzausrüstung ausgestattet und speziell geschult im Umgang mit Wespen. Wir arbeiten nach strengen Sicherheitsprotokollen und gewährleisten so den Schutz von Mensch und Umwelt bei jedem Einsatz.
                  </p>
                </div>
              </motion.div>
            </div>

            {/* Sidebar - 1 Column */}
            <div className="lg:col-span-1">
              <motion.div 
                className="bg-white rounded-xl shadow-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Einsatzbereiche</h3>
                <div className="space-y-6">
                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Privathaushalte</h4>
                    <ul className="space-y-2">
                      {['Dachböden', 'Rollladenkästen', 'Gartenhäuser', 'Terrassen & Balkone'].map((area) => (
                        <li key={area} className="flex items-center text-gray-600">
                          <Home className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{area}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Gewerbeobjekte</h4>
                    <ul className="space-y-2">
                      {['Bürogebäude', 'Industrieanlagen', 'Restaurants', 'Öffentliche Einrichtungen'].map((area) => (
                        <li key={area} className="flex items-center text-gray-600">
                          <Building2 className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{area}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
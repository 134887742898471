import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Thermometer, Clock, CheckCircle, AlertTriangle, Bug, Phone, Star, Leaf, FileText, HeartPulse, Building2, Bed, Home } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function Bettwanzenbekaempfung() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Bettwanzenbekämpfung mit Thermoverfahren | 100% Garantie ✓"
        description="Professionelle Bettwanzenbekämpfung ohne Gift ✓ Thermische Behandlung ✓ 99,9% Erfolgsquote ✓ Festpreisgarantie ➤ Kostenlose Erstberatung!"
        keywords="Bettwanzenbekämpfung, Thermoverfahren, Bettwanzen, Bayern, Oberösterreich"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1586768035999-0321c69e4523?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Bettwanzenbekämpfung mit Thermoverfahren
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              100% biologisch und garantiert wirksam - Ohne Gift und Chemie
            </p>
            <div className="flex flex-wrap gap-4">
              {[
                { icon: Shield, text: 'IHK-geprüft' },
                { icon: Star, text: 'Festpreisgarantie' },
                { icon: Leaf, text: 'Nachhaltige Methoden' }
              ].map((item, idx) => (
                <div key={idx} className="flex items-center bg-white/10 px-4 py-2 rounded-full">
                  <item.icon className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-white">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.section>

      {/* Main Content */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Services Grid */}
          <motion.div 
            className="grid md:grid-cols-3 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {[
              {
                icon: Thermometer,
                title: 'Thermische Behandlung',
                points: [
                  'Effektive Hitzebehandlung',
                  'Ohne Chemikalien',
                  'Umweltfreundlich',
                  'Nachhaltig wirksam'
                ]
              },
              {
                icon: Bug,
                title: 'Professionelle Analyse',
                points: [
                  'Gründliche Inspektion',
                  'Befallsermittlung',
                  'Dokumentation',
                  'Maßnahmenplanung'
                ]
              },
              {
                icon: Shield,
                title: 'Garantierte Wirkung',
                points: [
                  '100% Erfolgsgarantie',
                  'Nachkontrolle',
                  'Präventionsberatung',
                  'Langfristiger Schutz'
                ]
              }
            ].map((service, idx) => (
              <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                <service.icon className="h-12 w-12 text-[#e16b28] mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <ul className="space-y-3">
                  {service.points.map((point, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </motion.div>

          {/* Content Grid */}
          <div className="grid lg:grid-cols-3 gap-8">
            {/* Main Content - 2 Columns */}
            <div className="lg:col-span-2 space-y-8">
              <motion.div 
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  Professionelle Bettwanzenbekämpfung mit Thermoverfahren
                </h2>
                
                <div className="prose max-w-none">
                  <p className="mb-4">
                    Als führende Experten für Bettwanzenbekämpfung setzen wir auf das hocheffektive Thermoverfahren, das eine 100%ige Erfolgsgarantie bietet. Diese innovative Methode ermöglicht eine vollständig chemiefreie Bekämpfung von Bettwanzen in allen Entwicklungsstadien – von den Eiern bis zu den adulten Insekten. Durch die kontrollierte Erhitzung des betroffenen Bereichs auf über 55°C werden die Bettwanzen sowie ihre Eier zuverlässig abgetötet, ohne Rückstände oder Gesundheitsrisiken zu hinterlassen.
                  </p>

                  <h3 className="text-xl font-semibold text-gray-900 mt-8 mb-4">
                    Ganzheitlicher Ansatz zur Bettwanzenbekämpfung
                  </h3>
                  <p className="mb-4">
                    Unser bewährtes Verfahren basiert auf einem systematischen Vorgehen in mehreren Schritten. Zunächst führen wir eine gründliche Befallsanalyse durch, bei der wir das Ausmaß des Befalls und die betroffenen Bereiche genau dokumentieren. Anschließend entwickeln unsere Experten einen maßgeschneiderten Behandlungsplan, der die spezifischen Gegebenheiten vor Ort berücksichtigt. Die thermische Behandlung wird von unseren geschulten Fachkräften mit modernster Technik durchgeführt, wobei die Temperatur kontinuierlich überwacht wird, um optimale Ergebnisse zu gewährleisten.
                  </p>

                  <h3 className="text-xl font-semibold text-gray-900 mt-8 mb-4">
                    Vorteile der thermischen Bettwanzenbekämpfung
                  </h3>
                  <ul className="space-y-2 mb-4">
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                      <span>Garantierte Abtötung aller Entwicklungsstadien</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                      <span>Keine gesundheitsschädlichen Chemikalien</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                      <span>Keine Wartezeiten nach der Behandlung</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                      <span>Keine Resistenzbildung möglich</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                      <span>Umweltfreundlich und nachhaltig</span>
                    </li>
                    <li className="flex items-center">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                      <span>Auch für sensible Bereiche geeignet</span>
                    </li>
                  </ul>

                  <h3 className="text-xl font-semibold text-gray-900 mt-8 mb-4">
                    Erfahrung und Expertise
                  </h3>
                  <p>
                    Mit über 15 Jahren Erfahrung in der professionellen Schädlingsbekämpfung verfügen wir über umfassendes Know-how in der Bettwanzenbekämpfung. Unsere IHK-zertifizierten Fachkräfte werden regelmäßig geschult und setzen modernste Technik ein. Wir bieten unseren Service in ganz Bayern und Oberösterreich an und garantieren schnelle Reaktionszeiten bei akutem Befall.
                  </p>
                </div>
              </motion.div>
            </div>

            {/* Sidebar - 1 Column */}
            <div className="lg:col-span-1">
              <motion.div 
                className="bg-white rounded-xl shadow-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Einsatzbereiche</h3>
                <div className="space-y-6">
                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Privathaushalte</h4>
                    <ul className="space-y-2">
                      {['Schlafzimmer', 'Wohnzimmer', 'Kinderzimmer', 'Gästezimmer'].map((area) => (
                        <li key={area} className="flex items-center text-gray-600">
                          <Home className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{area}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Hotellerie</h4>
                    <ul className="space-y-2">
                      {['Hotelzimmer', 'Hostels', 'Pensionen', 'Ferienwohnungen'].map((area) => (
                        <li key={area} className="flex items-center text-gray-600">
                          <Building2 className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{area}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Weitere Bereiche</h4>
                    <ul className="space-y-2">
                      {['Pflegeeinrichtungen', 'Studentenwohnheime', 'Gemeinschaftsunterkünfte', 'Sozialeinrichtungen'].map((area) => (
                        <li key={area} className="flex items-center text-gray-600">
                          <Building2 className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{area}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
import { mainRoutes } from './mainRoutes';
import { serviceRoutes } from './serviceRoutes';
import { locationRoutes } from './locationRoutes';
import { blogRoutes } from './blogRoutes';

// Combine all routes
export const routes = [
  ...mainRoutes,
  ...serviceRoutes,
  ...locationRoutes,
  ...blogRoutes
];
import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Wrench, FileText, Leaf, MapPin, CheckCircle, AlertTriangle, Building2, Heart, Droplets, Star } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function Taubenkotreinigung() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Taubenkotreinigung & Desinfektion | Professionell & Gründlich"
        description="Professionelle Taubenkotreinigung & Desinfektion ✓ Festpreisgarantie ✓ Fachgerechte Entsorgung ✓ Vorbeugender Schutz ➤ Jetzt kostenlos beraten lassen!"
        keywords="Taubenkotreinigung, Taubenkotentfernung, Desinfektion, Bayern, Oberösterreich"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1504198580308-d186fefc6c97?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Professionelle Taubenkotreinigung & Desinfektion
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Gründliche Reinigung und fachgerechte Entsorgung von Taubenkot durch zertifizierte Experten
            </p>
            <div className="flex flex-wrap gap-4">
              {[
                { icon: Shield, text: 'Zertifizierte Experten' },
                { icon: Star, text: 'Festpreisgarantie' },
                { icon: Leaf, text: 'Umweltgerechte Entsorgung' }
              ].map((item, idx) => (
                <div key={idx} className="flex items-center bg-white/10 px-4 py-2 rounded-full">
                  <item.icon className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-white">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.section>

      {/* Rest of the component remains exactly the same */}
      {/* Gefahren durch Taubenkot */}
      <div className="relative -mt-10 z-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="bg-white rounded-xl shadow-xl p-6 border-l-4 border-[#e16b28]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-xl font-bold text-gray-900 mb-4">Gesundheitsrisiken durch Taubenkot:</h2>
            <div className="grid md:grid-cols-3 gap-4">
              {[
                'Übertragung von Krankheitserregern',
                'Allergische Reaktionen',
                'Atemwegserkrankungen',
                'Materialschäden an Gebäuden',
                'Rutschgefahr',
                'Hygienische Belastung'
              ].map((danger, idx) => (
                <div key={idx} className="flex items-center bg-gray-50 p-3 rounded-lg">
                  <AlertTriangle className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-gray-700">{danger}</span>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Services Grid */}
          <motion.div 
            className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {[
              {
                icon: Wrench,
                title: 'Reinigung',
                points: [
                  'Mechanische Entfernung',
                  'Hochdruckreinigung',
                  'Spezialgeräte',
                  'Gründliche Beseitigung'
                ]
              },
              {
                icon: Droplets,
                title: 'Desinfektion',
                points: [
                  'Professionelle Desinfektion',
                  'Geruchsbeseitigung',
                  'Keimfreie Oberflächen',
                  'Langanhaltende Wirkung'
                ]
              },
              {
                icon: Shield,
                title: 'Entsorgung',
                points: [
                  'Fachgerechte Entsorgung',
                  'Umweltgerecht',
                  'Dokumentation',
                  'Gesetzeskonform'
                ]
              }
            ].map((service, idx) => (
              <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                <service.icon className="h-12 w-12 text-[#e16b28] mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <ul className="space-y-3">
                  {service.points.map((point, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </motion.div>

          {/* Process Steps */}
          <motion.div 
            className="bg-white rounded-2xl shadow-xl p-8 mb-16"
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Unser Reinigungsprozess</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  title: 'Analyse & Vorbereitung',
                  points: [
                    'Bestandsaufnahme vor Ort',
                    'Gefährdungsbeurteilung',
                    'Schutzmaßnahmen',
                    'Arbeitsplanung'
                  ]
                },
                {
                  title: 'Durchführung',
                  points: [
                    'Professionelle Reinigung',
                    'Gründliche Desinfektion',
                    'Fachgerechte Entsorgung',
                    'Dokumentation'
                  ]
                }
              ].map((phase, idx) => (
                <div key={idx} className="bg-gray-50 rounded-xl p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">{phase.title}</h3>
                  <div className="space-y-3">
                    {phase.points.map((point, i) => (
                      <div key={i} className="flex items-center">
                        <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                        <span className="text-gray-700">{point}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Additional Services */}
          <motion.div 
            className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8 text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-2xl font-bold mb-6">Zusätzliche Leistungen</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  title: 'Prävention',
                  points: [
                    'Taubenabwehrsysteme',
                    'Beratung',
                    'Vorsorgende Maßnahmen'
                  ]
                },
                {
                  title: 'Sanierung',
                  points: [
                    'Oberflächensanierung',
                    'Beschichtungen',
                    'Reparaturen'
                  ]
                },
                {
                  title: 'Dokumentation',
                  points: [
                    'Fotodokumentation',
                    'Reinigungsprotokolle',
                    'Entsorgungsnachweise'
                  ]
                }
              ].map((service, idx) => (
                <div key={idx} className="space-y-4">
                  <h3 className="font-semibold text-lg flex items-center">
                    <Building2 className="h-5 w-5 text-[#e16b28] mr-2" />
                    {service.title}
                  </h3>
                  <ul className="space-y-2">
                    {service.points.map((point, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <CheckCircle className="h-4 w-4 text-[#e16b28] mr-2" />
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
}
import React from 'react';

interface ErrorStateProps {
  error: string;
}

export default function ErrorState({ error }: ErrorStateProps) {
  return (
    <div className="flex justify-center items-center min-h-[400px]">
      <div className="text-red-500 bg-red-50 px-6 py-4 rounded-lg">
        Fehler beim Laden: {error}
      </div>
    </div>
  );
}
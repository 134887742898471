import React from 'react';
import { motion } from 'framer-motion';
import { Bug, CheckCircle, AlertTriangle, BugOff, Shield, Clock, Leaf, Star } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function Schaedlingsbekaempfung() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Schädlingsbekämpfung Bayern & Oberösterreich | IHK-geprüft ✓"
        description="Professionelle Schädlingsbekämpfung für Privat- & Geschäftskunden. IHK-zertifizierte Experten, nachhaltige Methoden ✓ Festpreisgarantie ➤ Jetzt anfragen!"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1635340749291-c3ba5a48a0b2?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Professionelle Schädlingsbekämpfung
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Ihr zertifizierter Partner für Gesundheits- und Vorratsschutz in Bayern und Oberösterreich. 
              Schnell, zuverlässig und nachhaltig.
            </p>
          </div>
        </div>
      </motion.section>

      {/* Features Grid */}
      <div className="relative -mt-10 z-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="grid md:grid-cols-2 lg:grid-cols-4 gap-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {[
              {
                icon: Clock,
                title: 'Schneller Service',
                desc: 'Innerhalb von 24 Stunden vor Ort'
              },
              {
                icon: Shield,
                title: 'IHK-zertifiziert',
                desc: 'Geprüfte Fachkräfte & modernste Methoden'
              },
              {
                icon: Leaf,
                title: 'Nachhaltig',
                desc: 'Ökologische Bekämpfungsmethoden'
              },
              {
                icon: Star,
                title: 'Festpreisgarantie',
                desc: 'Transparente Kosten ohne Überraschungen'
              }
            ].map((feature, idx) => (
              <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                <feature.icon className="h-12 w-12 text-[#e16b28] mb-4" />
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.desc}</p>
              </div>
            ))}
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Content Grid */}
          <div className="grid lg:grid-cols-3 gap-8">
            {/* Main Content - 2 Columns */}
            <div className="lg:col-span-2 space-y-8">
              <motion.div 
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  Professionelle Schädlingsbekämpfung für höchste Ansprüche
                </h2>
                
                <div className="prose max-w-none">
                  <p className="mb-4">
                    Als IHK-geprüfter Schädlingsbekämpfer mit über 15 Jahren Erfahrung bieten wir Ihnen professionelle und nachhaltige Lösungen für alle Arten von Schädlingsproblemen. Unser Expertenteam verbindet traditionelles Handwerk mit modernster Technologie, um Ihnen bestmögliche Ergebnisse zu garantieren. Dabei setzen wir auf umweltschonende Verfahren und innovative Methoden, die sowohl effektiv als auch nachhaltig sind.
                  </p>

                  <p className="mb-4">
                    Unsere Dienstleistungen umfassen die Bekämpfung von Vorratsschädlingen, Gesundheitsschädlingen und Materialschädlingen. Mit unserem ganzheitlichen Ansatz bieten wir nicht nur die akute Schädlingsbekämpfung, sondern auch präventive Maßnahmen und langfristige Schutzkonzepte. Für Gewerbekunden entwickeln wir maßgeschneiderte HACCP-konforme Monitoring-Systeme, die eine lückenlose Dokumentation und Früherkennung ermöglichen.
                  </p>

                  <h3 className="text-xl font-semibold text-gray-900 mt-8 mb-4">
                    Ihr Experte für nachhaltige Schädlingsbekämpfung
                  </h3>

                  <p className="mb-4">
                    Die Qualität unserer Arbeit basiert auf drei wesentlichen Säulen: Fachkompetenz, modernste Technologie und kundenorientierter Service. Unsere Mitarbeiter durchlaufen regelmäßige Schulungen und Weiterbildungen, um stets auf dem neuesten Stand der Schädlingsbekämpfung zu sein. Dabei setzen wir auf eine umfassende Beratung und transparente Kommunikation mit unseren Kunden.
                  </p>

                  <p className="mb-4">
                    Für Privatkunden bieten wir schnelle und diskrete Hilfe bei akuten Schädlingsproblemen. Unser Service umfasst eine kostenlose Erstberatung, detaillierte Befallsanalyse und die Entwicklung einer maßgeschneiderten Bekämpfungsstrategie. Mit unserer Festpreisgarantie haben Sie von Anfang an Kostensicherheit - ohne versteckte Zusatzkosten.
                  </p>

                  <h3 className="text-xl font-semibold text-gray-900 mt-8 mb-4">
                    Zertifizierte Expertise für Ihre Sicherheit
                  </h3>

                  <p>
                    Als zertifizierter Fachbetrieb erfüllen wir höchste Qualitätsstandards und arbeiten nach den aktuellen gesetzlichen Vorschriften. Unsere Expertise erstreckt sich über verschiedene Bereiche der Schädlingsbekämpfung, von der Bettwanzenbekämpfung mit Thermoverfahren bis hin zur professionellen Taubenabwehr. Mit unserem flächendeckenden Service in Bayern und Oberösterreich sind wir immer in Ihrer Nähe und garantieren schnelle Reaktionszeiten.
                  </p>
                </div>
              </motion.div>
            </div>

            {/* Sidebar - 1 Column */}
            <div className="lg:col-span-1">
              <motion.div 
                className="bg-white rounded-xl shadow-lg p-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Schädlingsarten</h3>
                <div className="space-y-6">
                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Vorratsschädlinge</h4>
                    <ul className="space-y-2">
                      {['Brotkäfer', 'Getreideplattkäfer', 'Kornkäfer', 'Dörrobstmotte', 'Mehlkäfer', 'Mehlmilben'].map((pest) => (
                        <li key={pest} className="flex items-center text-gray-600">
                          <Bug className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{pest}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Gesundheitsschädlinge</h4>
                    <ul className="space-y-2">
                      {['Deutsche Schaben', 'Braunbandschaben', 'Orientalische Schaben', 'Bettwanzen', 'Katzenflöhe', 'Pharaoameisen'].map((pest) => (
                        <li key={pest} className="flex items-center text-gray-600">
                          <AlertTriangle className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{pest}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">Materialschädlinge</h4>
                    <ul className="space-y-2">
                      {['Gefleckter Pelzkäfer', 'Gemeiner Speckkäfer', 'Teppichkäfer', 'Wollkrautblütenkäfer'].map((pest) => (
                        <li key={pest} className="flex items-center text-gray-600">
                          <BugOff className="h-4 w-4 text-[#e16b28] mr-2" />
                          <span>{pest}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
import { Workbox } from 'workbox-window';
import { isPWASupported } from './environmentCheck';
import { PWALogger } from './logger';

export const registerServiceWorker = async () => {
  const support = isPWASupported();
  
  // Skip service worker registration in development and StackBlitz
  if (support.isDevelopment || support.isStackBlitz) {
    PWALogger.info('Service Worker registration skipped in development/preview. PWA features will be enabled in production.');
    return false;
  }

  // Check browser support
  if (!support.hasServiceWorker || !support.hasCache) {
    PWALogger.warn('PWA features are not supported in this browser.');
    return false;
  }

  try {
    const wb = new Workbox('/sw.js');
    
    wb.addEventListener('installed', (event) => {
      if (event.isUpdate) {
        PWALogger.info('New Service Worker installed');
      }
    });

    wb.addEventListener('activated', (event) => {
      PWALogger.info('Service Worker activated');
    });

    wb.addEventListener('controlling', () => {
      PWALogger.info('Service Worker is now controlling the page');
    });

    wb.addEventListener('waiting', (event) => {
      PWALogger.info('New Service Worker is waiting to activate');
    });

    wb.addEventListener('redundant', () => {
      PWALogger.warn('Service Worker became redundant');
    });

    wb.addEventListener('externalinstalled', (event) => {
      PWALogger.info('A new Service Worker was installed on another tab');
    });

    wb.addEventListener('externalactivated', (event) => {
      PWALogger.info('A new Service Worker was activated on another tab');
    });

    wb.addEventListener('externalwaiting', (event) => {
      PWALogger.info('A new Service Worker is waiting on another tab');
    });

    await wb.register();
    return true;
  } catch (error) {
    PWALogger.error('Service Worker registration failed', error);
    return false;
  }
};
import React from 'react';
import { Phone } from 'lucide-react';
import ContactForm from './ContactForm';

export default function Contact() {
  return (
    <section id="contact" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative rounded-2xl bg-gradient-to-r from-gray-900 to-gray-800 p-8 md:p-12 mb-16 overflow-hidden">
          <div className="relative z-10 max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">Kontakt</h2>
            <p className="text-xl text-gray-200">
              Kontaktieren Sie uns für eine kostenlose Inspektion und Beratung
            </p>
          </div>
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        </div>

        <div className="grid lg:grid-cols-12 gap-8">
          <div className="lg:col-span-8">
            <div className="bg-white rounded-2xl shadow-lg p-8 relative overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#e16b28] via-[#ff8f4d] to-[#e16b28]"></div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-8">Kontaktformular</h3>
              <ContactForm />
            </div>
          </div>

          <div className="lg:col-span-4 space-y-6">
            <div className="bg-white rounded-2xl shadow-lg p-6 relative overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-[#e16b28] via-[#ff8f4d] to-[#e16b28]"></div>
              <h3 className="text-xl font-semibold text-gray-900 mb-6">Auftragsannahme</h3>
              <div className="space-y-4">
                <p className="text-gray-600 font-medium">Öffnungszeiten:</p>
                <p className="text-gray-600">Mo-Do: 08:30-12:00 & 13:00-16:30</p>
                <p className="text-gray-600">Fr: 08:00-12:00</p>
                
                <div className="pt-4 space-y-4">
                  <div>
                    <p className="font-medium text-gray-900">Region Oberbayern:</p>
                    <a href="tel:+498921544167" className="text-[#e16b28] hover:text-[#c85d22]">
                      +49 (0) 89-21544167
                    </a>
                  </div>
                  
                  <div>
                    <p className="font-medium text-gray-900">Region Niederbayern:</p>
                    <a href="tel:+4985429699769" className="text-[#e16b28] hover:text-[#c85d22]">
                      +49 (0) 8542-9699769
                    </a>
                  </div>
                  
                  <div>
                    <p className="font-medium text-gray-900">Region Oberösterreich:</p>
                    <a href="tel:+43720317724" className="text-[#e16b28] hover:text-[#c85d22]">
                      +43 (0) 720-317724
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
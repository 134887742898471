import React from 'react';
import NavLink from './NavLink';
import DropdownMenu from './DropdownMenu';
import LoginButton from './LoginButton';

export default function DesktopNav() {
  return (
    <div className="hidden md:flex items-center space-x-8">
      <nav>
        <ul className="flex items-center space-x-6">
          <li>
            <NavLink to="/">Startseite</NavLink>
          </li>
          <li>
            <DropdownMenu />
          </li>
          <li>
            <NavLink to="/ueber-uns">Über Uns</NavLink>
          </li>
          <li>
            <NavLink to="/kontakt">Kontakt</NavLink>
          </li>
        </ul>
      </nav>
      <div className="pl-6 border-l border-gray-200">
        <LoginButton />
      </div>
    </div>
  );
}
import React from 'react';
import ContactForm from '../components/Contact';
import SEO from '../components/SEO';

export default function Contact() {
  return (
    <>
      <SEO 
        title="Kontakt | Höfler Schädlingsbekämpfung"
        description="Kontaktieren Sie uns für professionelle Schädlingsbekämpfung in Bayern & Oberösterreich. ✓ 24/7 Notdienst ✓ Kostenlose Erstberatung"
      />
      <div className="pt-24">
        <ContactForm />
      </div>
    </>
  );
}
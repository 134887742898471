import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Building2, Wrench, Leaf, MapPin, CheckCircle, AlertTriangle, Heart } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';

export default function Taubenabwehr() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="Taubenabwehr Bayern & Oberösterreich | Professionell & Nachhaltig"
        description="Effektive Taubenabwehr für Ihr Gebäude ✓ Tierfreundliche Methoden ✓ Dauerhafte Lösungen ✓ Kostenlose Beratung ➤ Jetzt anfragen!"
        keywords="Taubenabwehr, Taubenvergrämung, Taubenschutz, Bayern, Oberösterreich"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1621944190310-e3cca1564bd7?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Professionelle Taubenabwehr
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Nachhaltige und tierschutzgerechte Lösungen für effektiven Gebäudeschutz vor Tauben
            </p>
            <div className="flex flex-wrap gap-4">
              {[
                { icon: Shield, text: 'Zertifizierte Experten' },
                { icon: Heart, text: 'Tierschutzkonform' },
                { icon: Building2, text: 'Gebäudeschutz' }
              ].map((item, idx) => (
                <div key={idx} className="flex items-center bg-white/10 px-4 py-2 rounded-full">
                  <item.icon className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-white">{item.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.section>

      {/* Rest of the component remains exactly the same */}
      {/* Probleme durch Tauben */}
      <div className="relative -mt-10 z-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            className="bg-white rounded-xl shadow-xl p-6 border-l-4 border-[#e16b28]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-xl font-bold text-gray-900 mb-4">Probleme durch Taubenbefall:</h2>
            <div className="grid md:grid-cols-3 gap-4">
              {[
                'Gebäudeschäden durch Taubenkot',
                'Gesundheitsgefährdung',
                'Hygienische Belastung',
                'Optische Beeinträchtigung',
                'Rutschgefahr',
                'Lärmbelästigung'
              ].map((problem, idx) => (
                <div key={idx} className="flex items-center bg-gray-50 p-3 rounded-lg">
                  <AlertTriangle className="h-5 w-5 text-[#e16b28] mr-2" />
                  <span className="text-gray-700">{problem}</span>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Lösungen Grid */}
          <motion.div 
            className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {[
              {
                icon: Shield,
                title: 'Spikesysteme',
                points: [
                  'Edelstahlausführung',
                  'UV-beständig',
                  'Flexible Montage',
                  'Wartungsarm'
                ]
              },
              {
                icon: Building2,
                title: 'Netzsysteme',
                points: [
                  'Maßgeschneiderte Lösungen',
                  'Verschiedene Maschenweiten',
                  'UV-stabilisiert',
                  'Diskrete Installation'
                ]
              },
              {
                icon: Wrench,
                title: 'Elektrosysteme',
                points: [
                  'Niederspannungssystem',
                  'Witterungsbeständig',
                  'Wartungsfreundlich',
                  'Hohe Effektivität'
                ]
              }
            ].map((system, idx) => (
              <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                <system.icon className="h-12 w-12 text-[#e16b28] mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{system.title}</h3>
                <ul className="space-y-3">
                  {system.points.map((point, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </motion.div>

          {/* Vorteile */}
          <motion.div 
            className="bg-white rounded-2xl shadow-xl p-8 mb-16"
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Ihre Vorteile</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  title: 'Professionelle Umsetzung',
                  points: [
                    'Individuelle Beratung',
                    'Maßgeschneiderte Lösungen',
                    'Fachgerechte Montage',
                    'Qualitätsprodukte'
                  ]
                },
                {
                  title: 'Nachhaltiger Schutz',
                  points: [
                    'Langfristige Wirksamkeit',
                    'Tierschutzgerechte Methoden',
                    'Wartungsarme Systeme',
                    'Gebäudeschonendes Vorgehen'
                  ]
                }
              ].map((advantage, idx) => (
                <div key={idx} className="bg-gray-50 rounded-xl p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">{advantage.title}</h3>
                  <div className="space-y-3">
                    {advantage.points.map((point, i) => (
                      <div key={i} className="flex items-center">
                        <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2" />
                        <span className="text-gray-700">{point}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Einsatzbereiche */}
          <motion.div 
            className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl p-8 text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2 className="text-2xl font-bold mb-6">Einsatzbereiche</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  title: 'Gewerbeobjekte',
                  areas: [
                    'Industriehallen',
                    'Lagerhallen',
                    'Bürogebäude',
                    'Einkaufszentren'
                  ]
                },
                {
                  title: 'Öffentliche Gebäude',
                  areas: [
                    'Bahnhöfe',
                    'Schulen',
                    'Verwaltungsgebäude',
                    'Krankenhäuser'
                  ]
                },
                {
                  title: 'Historische Bauten',
                  areas: [
                    'Kirchen',
                    'Denkmäler',
                    'Schlösser',
                    'Altbauten'
                  ]
                }
              ].map((category, idx) => (
                <div key={idx} className="space-y-4">
                  <h3 className="font-semibold text-lg flex items-center">
                    <Building2 className="h-5 w-5 text-[#e16b28] mr-2" />
                    {category.title}
                  </h3>
                  <ul className="space-y-2">
                    {category.areas.map((area, i) => (
                      <li key={i} className="flex items-center text-gray-300">
                        <CheckCircle className="h-4 w-4 text-[#e16b28] mr-2" />
                        <span>{area}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
}
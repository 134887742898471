import { HoverStyles } from './types';
import { hoverStyles } from './config';

export function applyHoverStyles() {
  const styleSheet = document.createElement('style');
  let cssRules = '';

  Object.entries(hoverStyles).forEach(([className, styles]) => {
    // Default styles
    cssRules += `.${className} {`;
    Object.entries(styles.default).forEach(([property, value]) => {
      cssRules += `${property}: ${value};`;
    });
    if (styles.transition) {
      cssRules += `transition: ${styles.transition};`;
    }
    cssRules += '}';

    // Hover styles
    cssRules += `.${className}:hover {`;
    Object.entries(styles.hover).forEach(([property, value]) => {
      cssRules += `${property}: ${value};`;
    });
    cssRules += '}';
  });

  styleSheet.textContent = cssRules;
  document.head.appendChild(styleSheet);
}
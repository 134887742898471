import React from 'react';
import { ArrowRight } from 'lucide-react';
import { useContactForm } from '../hooks/useContactForm';
import FormField from './FormField';
import { inquiryTypes } from '../constants/formConstants';

export default function ContactForm() {
  const {
    formData,
    submitStatus,
    isSubmitting,
    focusedField,
    handleSubmit,
    handleChange,
    setFocusedField
  } = useContactForm();

  return (
    <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
      <div className="grid md:grid-cols-2 gap-4 md:gap-6">
        <FormField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          onFocus={() => setFocusedField('name')}
          onBlur={() => setFocusedField(null)}
          focused={focusedField === 'name'}
          required
        />
        <FormField
          label="Firma"
          name="company"
          value={formData.company}
          onChange={handleChange}
          onFocus={() => setFocusedField('company')}
          onBlur={() => setFocusedField(null)}
          focused={focusedField === 'company'}
        />
      </div>

      <div className="grid md:grid-cols-2 gap-4 md:gap-6">
        <FormField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          onFocus={() => setFocusedField('email')}
          onBlur={() => setFocusedField(null)}
          focused={focusedField === 'email'}
          required
        />
        <FormField
          label="Telefon"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handleChange}
          onFocus={() => setFocusedField('phone')}
          onBlur={() => setFocusedField(null)}
          focused={focusedField === 'phone'}
          required
        />
      </div>

      <div className="relative">
        <select
          name="inquiryType"
          id="inquiryType"
          value={formData.inquiryType}
          onChange={handleChange}
          onFocus={() => setFocusedField('inquiryType')}
          onBlur={() => setFocusedField(null)}
          className="w-full h-14 px-4 rounded-xl border-2 border-gray-200 bg-gray-50 focus:bg-white focus:border-[#e16b28] focus:ring focus:ring-[#e16b28]/20 transition-all duration-200 appearance-none"
          required
        >
          <option value="">Bitte wählen</option>
          {inquiryTypes.map(type => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
          <ArrowRight className="h-4 w-4 text-gray-400 transform rotate-90" />
        </div>
      </div>

      <FormField
        label="Nachricht"
        name="message"
        type="textarea"
        value={formData.message}
        onChange={handleChange}
        onFocus={() => setFocusedField('message')}
        onBlur={() => setFocusedField(null)}
        focused={focusedField === 'message'}
        required
      />

      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            type="checkbox"
            id="privacyConsent"
            name="privacyConsent"
            checked={formData.privacyConsent}
            onChange={handleChange}
            className="h-4 w-4 rounded border-gray-300 text-[#e16b28] focus:ring-[#e16b28]"
            required
          />
        </div>
        <label htmlFor="privacyConsent" className="ml-2 text-sm text-gray-600">
          Ich stimme der{' '}
          <a href="/datenschutz" className="text-[#e16b28] hover:text-[#c85d22] underline" target="_blank">
            Datenschutzerklärung
          </a>{' '}
          zu und willige in die Verarbeitung meiner Daten ein.
        </label>
      </div>

      {submitStatus && (
        <div className={`p-4 rounded-lg ${
          submitStatus.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
        }`}>
          {submitStatus.message}
        </div>
      )}

      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full inline-flex items-center justify-center bg-gradient-to-r from-[#e16b28] to-[#ff8f4d] text-white px-6 md:px-8 py-3 md:py-4 rounded-xl hover:from-[#c85d22] hover:to-[#e16b28] transform hover:-translate-y-0.5 transition-all duration-200 shadow-lg hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isSubmitting ? 'Wird gesendet...' : 'Nachricht senden'}
        <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
      </button>
    </form>
  );
}
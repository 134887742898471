import React from 'react';
import { Shield, Star, Leaf, Award, MapPin, CheckCircle, MonitorSmartphone, Sprout } from 'lucide-react';

export default function WhyChooseUs() {
  return (
    <section id="why-choose-us" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="relative rounded-2xl bg-gradient-to-r from-gray-900 to-gray-800 p-8 md:p-12 mb-16 overflow-hidden">
          <div className="relative z-10 max-w-3xl">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
              Moderne Schädlingsbekämpfung neu gedacht
            </h2>
            <p className="text-lg text-gray-200">
              Mit innovativer Technologie und digitalem Monitoring bieten wir maßgeschneiderte 
              Lösungen für jeden Bedarf. Unser Smart-Monitoring-System ermöglicht eine 
              lückenlose Früherkennung und Nachverfolgung.
            </p>
          </div>
          <div className="absolute right-0 top-0 w-1/3 h-full opacity-10">
            <MonitorSmartphone className="w-full h-full" />
          </div>
        </div>

        {/* Core Features Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {[
            { icon: Shield, title: 'IHK-geprüfter Fachbetrieb', desc: 'Zertifizierte Expertise mit HACCP-Konformität' },
            { icon: Star, title: 'Festpreisgarantie', desc: 'Transparente Preise ohne versteckte Kosten' },
            { icon: MonitorSmartphone, title: 'Smart-Monitoring', desc: 'Intelligente Fallensysteme mit Remote-Überwachung' },
            { icon: Sprout, title: 'Nachhaltige Bekämpfung', desc: 'Umweltschonende Methoden mit modernster Diagnostik' }
          ].map((feature, idx) => (
            <div key={idx} className="bg-gray-50 rounded-xl p-6 hover:shadow-lg transition-shadow">
              <feature.icon className="h-10 w-10 text-[#e16b28] mb-4" />
              <h3 className="text-lg font-semibold text-gray-900 mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.desc}</p>
            </div>
          ))}
        </div>

        <div className="grid lg:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-8">
            {/* Industries Section */}
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center mb-6">
                <Award className="h-7 w-7 text-[#e16b28] mr-3" />
                <h3 className="text-2xl font-semibold text-gray-900">Branchenexpertise</h3>
              </div>
              <div className="grid grid-cols-2 gap-y-4">
                {[
                  'Lebensmittelbetriebe (HACCP)',
                  'Logistik & Spedition',
                  'Hotellerie & Gastronomie',
                  'Industrie & Gewerbe',
                  'Pharmabetriebe',
                  'Hausverwaltungen'
                ].map((industry, idx) => (
                  <div key={idx} className="flex items-center">
                    <CheckCircle className="h-5 w-5 text-[#e16b28] mr-2 flex-shrink-0" />
                    <span className="text-gray-700">{industry}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Qualifications */}
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center mb-6">
                <Shield className="h-7 w-7 text-[#e16b28] mr-3" />
                <h3 className="text-2xl font-semibold text-gray-900">Qualifikationen</h3>
              </div>
              <div className="space-y-4">
                {[
                  'IHK-geprüfter Schädlingsbekämpfer',
                  'Sachkundenachweis nach §4 Abs.1a',
                  'Fallenjagd in befriedeten Bezirken',
                  'Fachberater Wasserschadensanierung'
                ].map((qual, idx) => (
                  <div key={idx} className="flex items-center bg-white p-4 rounded-lg">
                    <CheckCircle className="h-5 w-5 text-[#e16b28] mr-3" />
                    <span className="text-gray-700">{qual}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-8">
            {/* Service Regions */}
            <div className="bg-gray-50 rounded-xl p-8">
              <div className="flex items-center mb-6">
                <MapPin className="h-7 w-7 text-[#e16b28] mr-3" />
                <h3 className="text-2xl font-semibold text-gray-900">Ihr regionaler Partner</h3>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {[
                  { country: 'DE', region: 'Oberbayern' },
                  { country: 'DE', region: 'Niederbayern' },
                  { country: 'AT', region: 'Oberösterreich' },
                  { country: 'AT', region: 'Salzburg' }
                ].map((location, idx) => (
                  <div key={idx} className="bg-white p-4 rounded-lg flex items-center">
                    <span className="text-sm font-medium text-[#e16b28] mr-3">{location.country}</span>
                    <span className="text-gray-700">{location.region}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Technology Highlight */}
            <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-[#e16b28] to-[#c85d22] p-8 text-white">
              <div className="relative z-10">
                <h3 className="text-2xl font-semibold mb-4">Modernste Technologie</h3>
                <p className="text-gray-100">
                  Unsere digitalen Monitoring-Systeme ermöglichen eine präzise Früherkennung 
                  und effektive Bekämpfung von Schädlingen.
                </p>
              </div>
              <div className="absolute -right-8 -bottom-8 opacity-20">
                <MonitorSmartphone className="w-32 h-32" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
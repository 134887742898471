import React from 'react';
import { motion } from 'framer-motion';
import { Shield, ClipboardCheck, BarChart2, Bug, FileCheck, Wifi, CheckCircle, ArrowRight } from 'lucide-react';
import SEO from '../components/SEO';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

export default function HaccpMonitoring() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <>
      <SEO 
        title="HACCP-Monitoring & Dokumentation | IHK-zertifiziert ✓"
        description="Professionelles HACCP-Schädlingsmonitoring mit digitaler Dokumentation. Online-Zugriff ✓ IHK-zertifiziert ✓ Beratung anfordern!"
      />

      {/* Hero Section */}
      <motion.section 
        className="relative pt-32 pb-20 bg-gradient-to-br from-gray-900 to-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80')] opacity-10 bg-cover bg-center" />
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              HACCP-Monitoring nach HACCP
            </h1>
            <p className="text-xl text-gray-300">
              Professionelle Überwachung und Dokumentation für maximale Lebensmittelsicherheit
            </p>
          </div>
        </div>
      </motion.section>

      {/* Main Content */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Introduction */}
          <motion.div 
            className="bg-white rounded-2xl shadow-xl p-8 mb-12"
            {...fadeIn}
          >
            <p className="text-lg text-gray-700 leading-relaxed">
              Das HACCP-konforme Schädlingsmonitoring ist für Unternehmen in der Lebensmittelindustrie, 
              Gastronomie und anderen sensiblen Bereichen zwingend notwendig. Die Lebensmittelhygiene-Verordnung (LMHV) 
              schreibt vor, dass Schädlingsbefall durch geeignete Verfahren zu kontrollieren und zu bekämpfen ist.
            </p>
          </motion.div>

          {/* Features Grid */}
          <motion.div 
            className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            {[
              {
                icon: Shield,
                title: 'Sichere Kontrolle',
                description: 'Modernste Methoden und Technologien für frühzeitige Erkennung'
              },
              {
                icon: ClipboardCheck,
                title: 'Lückenlose Dokumentation',
                description: 'Online-Zugriff auf alle relevanten Informationen'
              },
              {
                icon: FileCheck,
                title: 'HACCP-Konformität',
                description: 'Erfüllung aller QS-Standards und HACCP-Anforderungen'
              },
              {
                icon: BarChart2,
                title: 'Individuelle Lösungen',
                description: 'Maßgeschneiderte Monitoring-Konzepte für Ihr Unternehmen'
              },
              {
                icon: Wifi,
                title: 'Smart Monitoring',
                description: 'Permanentmonitoring mit vernetzten Funkfallen'
              },
              {
                icon: Bug,
                title: 'Sofortige Reaktion',
                description: 'Schnelle Bekämpfung bei Schädlingsbefall'
              }
            ].map((feature, idx) => (
              <motion.div
                key={idx}
                className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <feature.icon className="h-12 w-12 text-[#e16b28] mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* Online Documentation System */}
          <motion.div 
            className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl shadow-xl p-8 text-white mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h2 className="text-3xl font-bold mb-8">Online-Dokumentationssystem</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                'Serviceberichte und Dokumentationen',
                'Lagepläne und Sicherheitsdatenblätter',
                'Befallsprotokolle und Befallskarten',
                'Alarme und Benachrichtigungen',
                'Barcode-gestütztes System',
                'Permanentmonitoring mit Funkfallen'
              ].map((item, idx) => (
                <div key={idx} className="flex items-center space-x-3 bg-white/10 rounded-lg p-4">
                  <CheckCircle className="h-5 w-5 text-[#e16b28]" />
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Services Overview */}
          <motion.div 
            className="grid md:grid-cols-2 gap-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            {[
              {
                title: 'Prävention',
                description: 'Beratung zu vorbeugenden Maßnahmen nach Risikoanalyse'
              },
              {
                title: 'Monitoring',
                description: 'Regelmäßige Kontrollen mit modernsten Detektionsmethoden'
              },
              {
                title: 'Bekämpfung',
                description: 'Schnelle und effektive Bekämpfung mit nachhaltigen Methoden'
              },
              {
                title: 'Dokumentation',
                description: 'Lückenlose Online-Dokumentation aller Leistungen'
              }
            ].map((service, idx) => (
              <motion.div
                key={idx}
                className="bg-white rounded-xl shadow-lg p-8"
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.2 }}
              >
                <h3 className="text-2xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* CTA Section */}
          <motion.div
            className="mt-16 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            <Link
              to="/kontakt"
              className="inline-flex items-center justify-center px-8 py-4 bg-[#e16b28] text-white rounded-xl hover:bg-[#c85d22] transition-colors shadow-lg hover:shadow-xl"
            >
              Kostenlose Beratung anfordern
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </motion.div>
        </div>
      </div>
    </>
  );
}
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { reportWebVitals, preloadCriticalAssets } from './utils/performance';
import { registerServiceWorker } from './utils/pwa/registerSW';
import { applyHoverStyles } from './utils/hover/applyHoverStyles';
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      retry: 1,
      refetchOnWindowFocus: false
    }
  }
});

// Apply hover styles
applyHoverStyles();

// Register service worker
registerServiceWorker();

// Preload critical assets
preloadCriticalAssets();

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </StrictMode>
);

// Report web vitals
reportWebVitals(console.log);
import { z } from 'zod';

const contactFormSchema = z.object({
  name: z.string().min(1, 'Name ist erforderlich'),
  email: z.string().email('Ungültige E-Mail-Adresse'),
  phone: z.string().min(1, 'Telefonnummer ist erforderlich'),
  message: z.string().min(1, 'Nachricht ist erforderlich')
});

type ContactFormData = z.infer<typeof contactFormSchema>;

export const sendContactForm = async (data: ContactFormData) => {
  try {
    // Validate input data
    const validatedData = contactFormSchema.parse(data);

    // Send email via Brevo
    const response = await fetch('https://api.brevo.com/v3/smtp/email', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'api-key': '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'
      },
      body: JSON.stringify({
        sender: {
          name: 'Kontaktformular Website',
          email: 'kontaktformular@hoefler-sbk.eu'
        },
        replyTo: {
          email: validatedData.email,
          name: validatedData.name
        },
        to: [{
          email: 'office@hoefler-sbk.eu',
          name: 'Höfler Schädlingsbekämpfung'
        }],
        subject: 'Neue Kontaktanfrage von der Website',
        htmlContent: `
          <h2>Neue Kontaktanfrage</h2>
          <p><strong>Name:</strong> ${validatedData.name}</p>
          <p><strong>E-Mail:</strong> ${validatedData.email}</p>
          <p><strong>Telefon:</strong> ${validatedData.phone}</p>
          <p><strong>Nachricht:</strong></p>
          <p>${validatedData.message}</p>
        `
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Fehler beim Senden der E-Mail');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending contact form:', error);
    throw error;
  }
};
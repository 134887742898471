import React from 'react';
import { Facebook, Mail, Phone, Twitter, MapPin, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/navigation';

export default function Footer() {
  return (
    <footer className="bg-gradient-to-br from-gray-900 to-gray-800 text-white" role="contentinfo">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Opening Hours Banner */}
        <div className="py-6 border-b border-gray-700">
          <div className="flex flex-col md:flex-row items-center justify-center gap-6 text-center">
            <div className="flex items-center text-[#e16b28]">
              <Clock className="h-6 w-6 mr-2" />
              <span className="font-semibold text-lg">Auftragsannahme</span>
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-8">
              <div className="text-gray-300">
                <span className="font-medium">Mo-Do:</span> 08:30-12:00 & 13:00-16:30
              </div>
              <div className="text-gray-300">
                <span className="font-medium">Fr:</span> 08:00-12:00
              </div>
            </div>
          </div>
        </div>

        {/* Main Footer Content */}
        <div className="py-12 grid grid-cols-1 md:grid-cols-12 gap-8">
          {/* Company Info */}
          <div className="md:col-span-4 space-y-6">
            <h3 className="text-2xl font-bold text-white">Höfler Schädlingsbekämpfung</h3>
            <p className="text-gray-400">
              Professionelle Schädlingsbekämpfung für Privat- und Geschäftskunden seit 2008.
              IHK-geprüft und zertifiziert.
            </p>
            <div className="flex space-x-4">
              <a 
                href="https://www.facebook.com/hoeflersbk.at" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-800 rounded-full"
                aria-label="Besuchen Sie uns auf Facebook"
              >
                <Facebook className="h-5 w-5" />
              </a>
              <a 
                href="https://x.com/hoeflersbk" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-800 rounded-full"
                aria-label="Folgen Sie uns auf X/Twitter"
              >
                <Twitter className="h-5 w-5" />
              </a>
              <a 
                href="mailto:office@hoefler-sbk.eu" 
                className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-800 rounded-full"
                aria-label="E-Mail senden"
              >
                <Mail className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="md:col-span-3">
            <h3 className="text-lg font-semibold mb-4">Navigation</h3>
            <ul className="space-y-2">
              {[
                { to: '/', label: 'Startseite' },
                { to: '/schaedlingsmonitoring', label: 'Monitoring' },
                { to: '/ueber-uns', label: 'Über Uns' },
                { to: '/kontakt', label: 'Kontakt' },
                { to: '/blog', label: 'Blog' },
                { to: '/impressum', label: 'Impressum' },
                { to: '/datenschutz', label: 'Datenschutz' }
              ].map((link) => (
                <li key={link.to}>
                  <Link 
                    to={link.to} 
                    onClick={scrollToTop}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div className="md:col-span-5">
            <h3 className="text-lg font-semibold mb-4">Standorte</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                {
                  region: 'Oberbayern',
                  office: 'München',
                  phone: '+49(0)89-21544167'
                },
                {
                  region: 'Niederbayern',
                  office: 'Passau',
                  phone: '+49(0)8542-9699769'
                },
                {
                  region: 'Oberösterreich',
                  office: 'Schärding',
                  phone: '+43(0)720-317724'
                }
              ].map((location, idx) => (
                <div key={idx} className="space-y-2">
                  <div className="flex items-center text-[#e16b28] font-semibold">
                    <MapPin className="h-4 w-4 mr-2" />
                    {location.region}
                  </div>
                  <div className="text-gray-400">
                    <p>Büro {location.office}</p>
                    <a 
                      href={`tel:${location.phone}`}
                      className="text-gray-400 hover:text-white transition-colors flex items-center gap-2 mt-1"
                    >
                      <Phone className="h-4 w-4" />
                      {location.phone}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-800 py-6 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Höfler Schädlingsbekämpfung. Alle Rechte vorbehalten.</p>
        </div>
      </div>
    </footer>
  );
}
import Schaedlingsbekaempfung from '../pages/Schaedlingsbekaempfung';
import Bettwanzenbekaempfung from '../pages/Bettwanzenbekaempfung';
import Wespenbekaempfung from '../pages/Wespenbekaempfung';
import Rattenbekaempfung from '../pages/Rattenbekaempfung';
import Taubenabwehr from '../pages/Taubenabwehr';
import Taubenkotreinigung from '../pages/Taubenkotreinigung';
import Holzschutz from '../pages/Holzschutz';
import HaccpMonitoring from '../pages/HaccpMonitoring';

export const serviceRoutes = [
  { path: '/schaedlingsbekaempfung', component: Schaedlingsbekaempfung },
  { path: '/bettwanzenbekaempfung', component: Bettwanzenbekaempfung },
  { path: '/wespenbekaempfung', component: Wespenbekaempfung },
  { path: '/rattenbekaempfung', component: Rattenbekaempfung },
  { path: '/taubenabwehr', component: Taubenabwehr },
  { path: '/taubenkotreinigung', component: Taubenkotreinigung },
  { path: '/holzschutz', component: Holzschutz },
  { path: '/schaedlingsmonitoring', component: HaccpMonitoring }
];
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Search, ArrowRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { serviceAreas } from '../routes/locations';

export default function LocationSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState<Array<{city: string; slug: string; region: string}>>([]);
  const searchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const matchesZipCode = (zipCodes: string[], searchZip: string) => {
    return zipCodes.some(zipPattern => {
      const pattern = zipPattern.replace('*', '');
      return searchZip.startsWith(pattern);
    });
  };

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const searchResults: Array<{city: string; slug: string; region: string}> = [];
      const searchTermLower = searchTerm.toLowerCase();
      const isZipSearch = /^\d+$/.test(searchTerm);

      Object.entries(serviceAreas).forEach(([_, regions]) => {
        Object.entries(regions).forEach(([regionName, cities]) => {
          cities.forEach(city => {
            const matches = 
              city.city.toLowerCase().includes(searchTermLower) ||
              city.districts.some(district => 
                district.toLowerCase().includes(searchTermLower)
              ) ||
              (isZipSearch && city.zipCodes && matchesZipCode(city.zipCodes, searchTerm));

            if (matches) {
              searchResults.push({
                city: city.city,
                slug: city.slug,
                region: regionName
              });
            }
          });
        });
      });
      setResults(searchResults);
      setIsOpen(true);
    } else {
      setResults([]);
      setIsOpen(false);
    }
  }, [searchTerm]);

  return (
    <div className="max-w-2xl mx-auto px-4">
      <div className="relative" ref={searchRef}>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-4 border-2 border-gray-200 rounded-xl focus:ring-[#e16b28] focus:border-[#e16b28] bg-white shadow-lg text-gray-900 placeholder-gray-500"
            placeholder="Stadt oder Postleitzahl eingeben..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsOpen(true)}
          />
        </div>

        <AnimatePresence>
          {isOpen && results.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="absolute z-40 w-full mt-2 bg-white rounded-xl shadow-xl border border-gray-100 overflow-hidden"
            >
              <div className="max-h-96 overflow-y-auto">
                {results.map((result, index) => (
                  <motion.button
                    key={result.slug}
                    className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 text-left"
                    onClick={() => {
                      navigate(`/dienstleistungen/schaedlingsbekaempfung-${result.slug}`);
                      setIsOpen(false);
                      setSearchTerm('');
                    }}
                    whileHover={{ x: 5 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="flex items-center">
                      <MapPin className="h-5 w-5 text-[#e16b28] mr-3" />
                      <div>
                        <span className="block font-medium text-gray-900">
                          {result.city}
                        </span>
                        <span className="block text-sm text-gray-500">
                          Region {result.region}
                        </span>
                      </div>
                    </div>
                    <ArrowRight className="h-4 w-4 text-gray-400" />
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {isOpen && results.length === 0 && searchTerm.length >= 2 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="absolute z-40 w-full mt-2 p-4 bg-white rounded-xl shadow-xl border border-gray-100"
          >
            <p className="text-gray-500 text-center">
              Keine Ergebnisse für "{searchTerm}" gefunden
            </p>
          </motion.div>
        )}
      </div>
    </div>
  );
}
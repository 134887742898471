export const PWALogger = {
  info: (message: string) => {
    if (process.env.NODE_ENV !== 'production') {
      console.info('[PWA]', message);
    }
  },
  
  warn: (message: string) => {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('[PWA]', message);
    }
  },
  
  error: (message: string, error?: Error) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('[PWA]', message, error);
    }
  },
  
  debug: (message: string, data?: any) => {
    if (process.env.NODE_ENV === 'development') {
      console.debug('[PWA]', message, data);
    }
  }
};
import { HoverStyles } from './types';

export const hoverStyles: HoverStyles = {
  'menu-item': {
    default: {
      transform: 'translateY(0) scale(1)',
      backgroundColor: 'transparent',
      color: 'rgb(75, 85, 99)'
    },
    hover: {
      transform: 'translateY(-1px) scale(1.02)',
      backgroundColor: 'rgb(249, 250, 251)',
      color: 'rgb(225, 107, 40)'
    },
    transition: 'all 0.35s cubic-bezier(0.4, 0, 0.2, 1)'
  },
  'nav-link': {
    default: {
      color: 'rgb(75, 85, 99)',
      transform: 'translateY(0)'
    },
    hover: {
      color: 'rgb(225, 107, 40)',
      transform: 'translateY(-1px)'
    },
    transition: 'all 0.35s cubic-bezier(0.4, 0, 0.2, 1)'
  }
};
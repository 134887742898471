import React from 'react';
import { LogIn } from 'lucide-react';

export default function LoginButton() {
  return (
    <a
      href="https://logbook.pestscan.eu/ps_web/Login.aspx?HOFL"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-[#e16b28] hover:bg-[#c85d22] transition-colors shadow-sm hover:shadow-md"
    >
      <LogIn className="h-4 w-4 mr-2" />
      HACCP Login
    </a>
  );
}
import { useCallback } from 'react';
import { hoverStyles } from './config';

export function useHoverEffect(className: keyof typeof hoverStyles) {
  return useCallback(() => {
    const styles = hoverStyles[className];
    if (!styles) {
      console.warn(`No hover styles found for className: ${className}`);
      return {};
    }

    return {
      className,
      style: {
        ...styles.default,
        transition: styles.transition
      }
    };
  }, [className]);
}
import React from 'react';
import Navbar from '../navigation/Navbar';
import Footer from '../Footer';
import ScrollToTopButton from '../ScrollToTopButton';
import ScrollToTop from '../ScrollToTop';
import CookieConsent from '../CookieConsent';
import ValidationPanel from '../debug/ValidationPanel';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen bg-white">
      <ScrollToTop />
      <Navbar />
      {children}
      <Footer />
      <CookieConsent />
      <ScrollToTopButton />
      {process.env.NODE_ENV === 'development' && <ValidationPanel />}
    </div>
  );
}
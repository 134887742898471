import React from 'react';
import { useNavigation } from './useNavigation';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import Logo from '../Logo';

export default function Navbar() {
  const { isScrolled, isOpen, setIsOpen } = useNavigation();

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-40 bg-white transition-all duration-200 ${
        isScrolled ? 'shadow-lg' : ''
      }`}
    >
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="flex justify-between items-center h-24">
          <Logo />
          <DesktopNav />
          <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </nav>
  );
}